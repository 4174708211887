// extracted by mini-css-extract-plugin
export const graph = "graphs6_0-module--graph--3Ue9y";
export const legendbox = "graphs6_0-module--legendbox--1G9dK";
export const legend2 = "graphs6_0-module--legend2--3eaWC";
export const legendtxt = "graphs6_0-module--legendtxt--2bkZ8";
export const legendline1 = "graphs6_0-module--legendline1--3CSAh";
export const legendline2 = "graphs6_0-module--legendline2--3R1bR";
export const legendline3 = "graphs6_0-module--legendline3--rI-0Q";
export const box = "graphs6_0-module--box--5l-AK";
export const scene = "graphs6_0-module--scene--15wK0";
export const legend = "graphs6_0-module--legend--UZYaa";
export const greedy = "graphs6_0-module--greedy--3Sfuv";
export const yaxe = "graphs6_0-module--yaxe--auVkv";
export const yaxevalue = "graphs6_0-module--yaxevalue--3tmqD";
export const greedx = "graphs6_0-module--greedx--2vz_s";
export const xaxe = "graphs6_0-module--xaxe--3AV4g";
export const xaxevalue = "graphs6_0-module--xaxevalue--1GOTP";
export const svg = "graphs6_0-module--svg--MtJhM";
export const ubsclip60 = "graphs6_0-module--ubsclip60--2Whbf";