// extracted by mini-css-extract-plugin
export const graph = "graphs3_0-module--graph--3oKaY";
export const legendbox = "graphs3_0-module--legendbox--2E6IG";
export const legend2 = "graphs3_0-module--legend2--E1NMm";
export const legendtxt = "graphs3_0-module--legendtxt--2qFu0";
export const legendline1 = "graphs3_0-module--legendline1--3eQr7";
export const legendline2 = "graphs3_0-module--legendline2--24QEw";
export const legend3 = "graphs3_0-module--legend3--19gBw";
export const box = "graphs3_0-module--box--2wgqD";
export const scene = "graphs3_0-module--scene--1mLTo";
export const legend = "graphs3_0-module--legend--2vj0q";
export const greedy = "graphs3_0-module--greedy--NQINu";
export const yaxe = "graphs3_0-module--yaxe--xGbr1";
export const yaxevalue = "graphs3_0-module--yaxevalue--lIT9d";
export const greedx = "graphs3_0-module--greedx--GNPNO";
export const xaxe = "graphs3_0-module--xaxe--3zLkh";
export const xaxevalue = "graphs3_0-module--xaxevalue--38-kw";
export const svg = "graphs3_0-module--svg--1y4En";
export const ubsclip30 = "graphs3_0-module--ubsclip30--cJNC2";