import React from 'react'

import * as s from "./mega.module.css"

import { COLORS } from '../assets/icons.js'

import Graph from "./graph"
import Focus from "./focus"

import Ill0 from '../assets/ill5_0.svg';
import Ill1 from '../assets/ill5_1.svg';

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";


const Mega5 = (props) => {

	const data = props.data;

	return (
	<section className={s.mega5}>

<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[2] }} />
</ScrollAnimation>

		<div className={s.lineblock}>
			<div className={s.lineblockline} style={{backgroundColor:COLORS[5]}}/>
			<Graph color={COLORS[5]} data={data.graph1} nm={"5_0"} />

			<div className={s.textblock}>
				<div className={s.textblockicon} >
				</div>
				<div className={s.textblock4}>
				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[3] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[4] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[5] }} />
</ScrollAnimation>
				</div>
			</div>

			<div className={s.graphtext} data-id={"5_1"}>
				<Graph color={COLORS[5]} data={data.graph2} nm={"5_1"} />
				<div className={s.gtb2}>
				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.ptt} dangerouslySetInnerHTML={{__html: data.txt[6] }} />
</ScrollAnimation>
				</div>
			</div>

			<div className={s.ill51}>
				<Ill0 />
			</div>

			<Graph color={COLORS[5]} data={data.graph3} nm={"5_2"} />


			<div className={s.textblock}>
				<div className={s.textblockicon} >
				</div>
				<div className={s.textblock4}>
				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[7] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[8] }} />
</ScrollAnimation>
				</div>
			</div>

			<div className={s.textblock}>
				<div className={s.textblockicon} data-id={5}>
					<Ill1 />
				</div>
				<div>
				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.p6} dangerouslySetInnerHTML={{__html: data.txt[9] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		
					<p className={s.p6} dangerouslySetInnerHTML={{__html: data.txt[10] }} />
</ScrollAnimation>
				</div>
			</div>

			<Focus cid={5} data={data.focus} />
		</div>

<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		

		<div className={s.sources} >
			<div className={s.sourcestitle} dangerouslySetInnerHTML={{__html: `Sources:` }} />
				{
					data.sources.map((d,i) => (
						<div
							key={`k`+i}
							className={s.source}
							dangerouslySetInnerHTML={{__html: d }}
						/>
					))
				}
		</div>
</ScrollAnimation>


	</section>
)}

export default Mega5