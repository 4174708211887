import React from 'react'

import * as s from "./mega.module.css"

import { COLORS } from '../assets/icons.js'

import Graph from "./graph"


import Ill0 from '../assets/ill3_0.svg';
import Ill1 from '../assets/ill3_1.svg';

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";



const Mega3 = (props) => {

	const data = props.data;

	return (
	<section className={s.mega3}>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
</ScrollAnimation>

		<div className={s.textblock} data-id="3">
			<div className={s.textblockicon} data-id={3}>
				<Ill0 />
			</div>
			<div>
								<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
				<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
					</ScrollAnimation>
										<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
				<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[2] }} />
					</ScrollAnimation>
										<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
				<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[3] }} />
					</ScrollAnimation>
			</div>
		</div>


		<div className={s.lineblock}>
			<div className={s.lineblockline} style={{backgroundColor:COLORS[3]}}/>
			
			<Graph color={COLORS[3]} data={data.graph1} nm={"3_0"}/>

			<div className={s.textblock}>
				<div className={s.textblockicon}>

				</div>
				<div>
									<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[4] }} />
					</ScrollAnimation>
										<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[5] }} />
					</ScrollAnimation>
				</div>
			</div>

			<div className={s.ill31}>
				<Ill1 />
			</div>

			<Graph color={COLORS[3]} data={data.graph2} nm={"3_1"}/>

		</div>

<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[6] }} />
		</ScrollAnimation>

		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<div className={s.sources} data-id={3}>
			<div className={s.sourcestitle} dangerouslySetInnerHTML={{__html: `Sources:` }} />
				{
					data.sources.map((d,i) => (
						<div
							key={`k`+i}
							className={s.source}
							dangerouslySetInnerHTML={{__html: d }}
						/>
					))
				}
		</div>
		</ScrollAnimation>


	</section>
)}

export default Mega3