import React, { useEffect, useState } from 'react'

import * as s from "./graphs0_0.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'
import { track } from '../../utils/index.js'
import Select from 'react-select'


const Graph0_0 = (props) => {

	const data = props.data;
	const [val, setVal] = useState(0);
	const yaxis = [-6, -5, -4, -3, -2, -1, 0]

	let options = data.data.map((d,i) => ( { value: i, label: d.name } ))
	options.sort(function (a, b) {
	  if (a.label > b.label) {
	    return 1;
	  }
	  if (a.label < b.label) {
	    return -1;
	  }
	  return 0;
	});

	const customStyles = {
		control: (styles, state) => ({ 
			...styles, 
			backgroundColor: `#F5F5F5`,
			color: `white`,
			borderRadius: 0,
			border: 0,
    		boxShadow: 'none',
    		height: 46,
			padding: 0,
			cursor: 'pointer'
		}),
		option: (styles, { isSelected }) => ({
			...styles, 
			backgroundColor: isSelected ? `#4B9399` : `#F5F5F5`,
			':hover': { backgroundColor: `#00B359`, color: `white` },
			color: `#00B359`,
			borderRadius: 0,
			cursor: 'pointer'
		}),
		placeholder: styles => ({ ...styles, color: `#00B359` }),
		singleValue: styles => ({ ...styles, color: `#00B359` }),
		input: styles => ({ ...styles, color: `#00B359` }),
		menu: styles => ({ ...styles, backgroundColor: `#F5F5F5`, marginTop: 0, marginBottom: 0, cursor: 'pointer' }),
		menuList: (provided, state) => ({
		   ...provided,
		   paddingTop: 0,
		   paddingBottom: 0,
		}),
	}

	useEffect(() => {
		gsap.set(`.${s.barline}`, {height: `0%`, transformOrigin: `center center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.barline}`, {height: `100%`, duration: 0.25, ease: `power3.out`, stagger: 0.005});
	}, [props.fired]);


	return (
	<section className={s.graph}>

		<div className={s.selectbox}>
			<Select
				instanceId="ubs-select0"
				placeholder={options.find(d => d.value === val).label}
				options={options}
				styles={customStyles}
				className={s.select}
				isSearchable={false}
				components={{IndicatorSeparator: () => null}}
				onKeyDown={(e)=>{}}
				onChange={(e)=>{
					track(6, options.find(d => d.value === e.value).label);
					setVal(e.value)}}
				value={options.find(d => d.value === val).label}
			/>
		</div>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greed}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`k`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d + `%` }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.legend} dangerouslySetInnerHTML={{__html: data.legend }} />
				<div className={s.bars} >
				{
		          data.data.map((d,i) => (
		            <div
		              key={`k`+i}
		              className={s.barbox}
						onKeyDown={()=>{}}
						role="button"
						aria-label="bar"
						tabIndex={0}
						onClick={()=>{
							track(6, options.find(d => d.value === i).name);
							setVal(i);
						}}


		            >
						<div className={s.baranimated}>
							<div className={s.bar + ((i===val)?` ${s.bar_active}`:``)} style={{height: `${(100/6) * Math.abs(d.value)}%`}} >
								<div className={s.nm} dangerouslySetInnerHTML={{__html: d.nm }} />
								<div className={s.barline} />
								<div className={s.tip}>
									<span>{d.value + `%`}</span>
									<div className={s.arrow}>
										<svg xmlns="http://www.w3.org/2000/svg" width="6.493" height="6.493" viewBox="0 0 6.493 6.493">
											<rect width="4.591" height="4.591" transform="translate(3.246) rotate(45)" fill="#00b359"/>
										</svg>
									</div>
								</div>
							</div>
						</div>
		            </div>
		          ))
		        }
				</div>
			</div>
		</div>

	</section>
)}

export default Graph0_0