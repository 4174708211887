import React, { useEffect } from 'react'

import * as s from "./graphs6_0.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'


const Graph6_0 = (props) => {

	const data = props.data;
	const xaxis = [1990, 1993, 1996, 1999, 2002, 2005, 2008, 2011, 2014, 2017, 2020]
	const yaxis = [60, 50, 40, 30, 20, 10, 0]


	useEffect(() => {
		gsap.set(`.${s.ubsclip60}`, {width: `0%`, transformOrigin: `left center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.ubsclip60}`, {width: `100%`, duration: 1.5, ease: `power2.out`, stagger: 0.1});
	}, [props.fired]);


	return (
	<section className={s.graph}>

		<div className={s.legendbox}>
			<div className={s.legend2}>
				<div className={s.legendline1} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline2} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[2] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline3} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[3] }} />
			</div>
		</div>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greedy}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`y`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d + `%` }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.greedx}>
					{
						xaxis.map((d,i) => (
				            <div
				              key={`x`+i}
				              className={s.xaxe}
				            >
				            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.legend} dangerouslySetInnerHTML={{__html: data.legend[0] }} />

				<svg className={s.svg} xmlns="http://www.w3.org/2000/svg" width="100%" height="330" viewBox="0 0 992 330" preserveAspectRatio="none">
					<defs>
						<clipPath id="ubsclip600">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip60} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip601">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip60} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip602">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip60} fill="#d5eaf4"/>
						</clipPath>
					</defs>
					<g clipPath="url(#ubsclip600)">
						<path d="M118.414,680.291s28.269,8.514,49.222,10.811,41.1,6,41.1,6H385.476l63.71,4,37,11c-2.063,0,26.709,6,26.709,6l47.277,9c-2.063,0,51.8-1.789,76.041,0s61.655,5,61.655,5c-2.063,0,15.47,6.535,36.993,15s36.993,9,36.993,9H930.1s24.429-4.648,55.49,0,49.324,7,49.324,7h57.529l7.908-16" transform="translate(-116.627 -619.686)" fill="none" stroke="#bb261a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="1 4" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip601)">
						<path d="M121.86,805.858s51.885-10.939,75.548-12.756,66.219,0,66.219,0,55.4,2.953,84.861,0,70.366,0,70.366,0h41.392l64.157-13,74.5-7h55.887l53.8-7,28.966-5,22.774-12s46.711-1.316,66.235,0,66.154,3.148,93.132,0,64.157-5,64.157-5h49.662l51.732-4h12.434v26" transform="translate(-116.627 -638.616)" fill="none" stroke="#bb261a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip602)">
						<path d="M119.151,895.392h35.365l47.248-4.29h63.693s32.32.492,57.537,0,55.474,0,55.474,0l43.147-5h34.928s17.93-7.465,45.2-6,32.8-2.445,57.529,0,55.474,0,55.474,0h84.239s30.418-6.656,59.583-5,76.02,5,76.02,5,85.073-6.129,110.949-5,82.184,0,82.184,0h63.693v-18l4.218,9.993" transform="translate(-116.627 -666.955)" fill="none" stroke="#bb261a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="10 10" vectorEffect="non-scaling-stroke"/>
					</g>
				</svg>

			</div>
		</div>

	</section>
)}

export default Graph6_0