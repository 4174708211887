// extracted by mini-css-extract-plugin
export const graph = "graphs4_1-module--graph--35YD5";
export const table = "graphs4_1-module--table--1iii1";
export const greedx = "graphs4_1-module--greedx--3pjcL";
export const xaxe = "graphs4_1-module--xaxe--2KgTV";
export const xaxevalue = "graphs4_1-module--xaxevalue--2xnA2";
export const tablebox = "graphs4_1-module--tablebox--OCyBj";
export const tableline = "graphs4_1-module--tableline--TWDY1";
export const tabletd0 = "graphs4_1-module--tabletd0--2YuZA";
export const tabletd1 = "graphs4_1-module--tabletd1--3qS4c";
export const tablebaranimated = "graphs4_1-module--tablebaranimated--_8gQE";
export const tablebar0 = "graphs4_1-module--tablebar0--1PUrC";
export const tablebarvalue = "graphs4_1-module--tablebarvalue--23P6q";
export const legend2 = "graphs4_1-module--legend2--3mhmZ";
export const legend = "graphs4_1-module--legend--3YIH1";
export const legendline = "graphs4_1-module--legendline--1m6BP";
export const legendqube = "graphs4_1-module--legendqube--m77CL";
export const legendtxt = "graphs4_1-module--legendtxt--2LpDG";
export const ramka = "graphs4_1-module--ramka--u3Vhk";