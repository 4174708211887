import React, { useEffect } from 'react'

import * as s from "./graphs4_0.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

// import Select from 'react-select'

import G40 from '../../assets/g40.svg'
import Arrow from '../../assets/arrow2.svg'

const Graph4_0 = (props) => {
	const data = props.data;
	const xaxis = [`20%`, `30%`, `40%`, `50%`, `60%`, `70%`, `80%`];
	const yaxis = [4,3,2,1,0,-1,-2];

	useEffect(() => {
		gsap.set(`.ubs-g40`, {opacity: `0`, transformOrigin: `center center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.ubs-g40`, {opacity: `1`, duration: 1.5, ease: `power4.out`, stagger: 0.1});
	}, [props.fired]);


	return (
	<section className={s.graph}>



		<div className={s.swipe}>
			<div className={s.swipetext} dangerouslySetInnerHTML={{__html: `Swipe to explore` }} />
			<Arrow />
		</div>


		<div className={s.legendup}>
			<div dangerouslySetInnerHTML={{__html: data.legend[0] }} />
		</div>





		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greedy}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`y`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d + `%`}} />
				            </div>
			            ))
					}
				</div>

				<div className={s.fieldbox}>
					<div className={s.fieldboxscroll}>

						<div className={s.greedx}>
							{
								xaxis.map((d,i) => (
						            <div
						              key={`x`+i}
						              className={s.xaxe}
						              data-id={i}
						            >
						            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
						            </div>
					            ))
							}
						</div>

						<div className={s.field}>
							<G40 />
						</div>



					</div>
				</div>


			</div>
		</div>

		<div className={s.legenddown}>
			<div className={s.legend2} dangerouslySetInnerHTML={{__html: data.legend[2]}} />
			
			<svg xmlns="http://www.w3.org/2000/svg" width="25.187" height="6.463" viewBox="0 0 25.187 6.463">
			  <g transform="translate(-203.74 -222.588)">
			    <line x2="24.125" transform="translate(204.301 225.795)" fill="none" stroke="#242830" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
			    <path d="M207.765,227.344l-2.524-2.524,2.524-2.524" transform="translate(-1 1)" fill="none" stroke="#242830" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
			  </g>
			</svg>

			<div className={s.legend3} dangerouslySetInnerHTML={{__html: data.legend[3] }} />

			<svg xmlns="http://www.w3.org/2000/svg" width="25.187" height="6.463" viewBox="0 0 25.187 6.463">
			  <g transform="translate(228.927 229.051) rotate(180)">
			    <line x2="24.125" transform="translate(204.301 225.795)" fill="none" stroke="#242830" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"/>
			    <path d="M207.765,227.344l-2.524-2.524,2.524-2.524" transform="translate(-1 1)" fill="none" stroke="#242830" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1"/>
			  </g>
			</svg>

			<div className={s.legend4} dangerouslySetInnerHTML={{__html: data.legend[4] }} />
		</div>

	</section>
)}

export default Graph4_0