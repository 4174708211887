// extracted by mini-css-extract-plugin
export const graph = "graphs0_1-module--graph--3kQd_";
export const table = "graphs0_1-module--table--3DBlr";
export const tablehead = "graphs0_1-module--tablehead--3hE6b";
export const tabletd1 = "graphs0_1-module--tabletd1--thqbp";
export const tabletd2 = "graphs0_1-module--tabletd2--ijsko";
export const tabletd3 = "graphs0_1-module--tabletd3--11F0E";
export const tablebox = "graphs0_1-module--tablebox--3br2M";
export const tableline = "graphs0_1-module--tableline--1PK94";
export const tabletd0 = "graphs0_1-module--tabletd0--3xiJQ";
export const tablebaranimated = "graphs0_1-module--tablebaranimated--2y8jQ";
export const tablebar = "graphs0_1-module--tablebar--3HcS0";