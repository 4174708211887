// extracted by mini-css-extract-plugin
export const graph = "graphs1_1-module--graph--_56Kn";
export const table = "graphs1_1-module--table--3wU9v";
export const greedx = "graphs1_1-module--greedx--1vjbd";
export const xaxe = "graphs1_1-module--xaxe--2Pcvi";
export const xaxevalue = "graphs1_1-module--xaxevalue--33xmN";
export const tablebox = "graphs1_1-module--tablebox--hQTmI";
export const tableline = "graphs1_1-module--tableline--1ixwy";
export const tabletd0 = "graphs1_1-module--tabletd0--F43XN";
export const tabletd1 = "graphs1_1-module--tabletd1--2hV74";
export const tablebaranimated = "graphs1_1-module--tablebaranimated--3KXqS";
export const tablebaranimated2 = "graphs1_1-module--tablebaranimated2--393Rh";
export const tablebar0 = "graphs1_1-module--tablebar0--31gWC";
export const tablebar1 = "graphs1_1-module--tablebar1--1uRVf";
export const legend = "graphs1_1-module--legend--3ZGRl";
export const legendline = "graphs1_1-module--legendline--3msIw";
export const legendqube = "graphs1_1-module--legendqube--Q5Whj";
export const legendtxt = "graphs1_1-module--legendtxt--1H14N";