import React from 'react'

import * as s from "./mega.module.css"

import { COLORS } from '../assets/icons.js'

import Graph from "./graph"
import Focus from "./focus"

import Ill0 from '../assets/ill0_0.svg';
import Ill1 from '../assets/ill0_1.svg';



import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";


const Mega0 = (props) => {

	const data = props.data;

	return (
	<section className={s.mega0}>
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
			<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
		</ScrollAnimation>
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
			<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
		</ScrollAnimation>
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
			<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[2] }} />
		</ScrollAnimation>

		<div className={s.lineblock}>
			<div className={s.lineblockline} style={{backgroundColor:COLORS[0]}}/>
			
			<Graph color={COLORS[0]} data={data.graph1} nm={"0_0"} />
			
			<div className={s.textblock}>
				<div className={s.textblockicon}>
					<Ill0 />
				</div>
				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[3] }} />
				</ScrollAnimation>
			</div>

			<Graph color={COLORS[0]} data={data.graph2} nm={"0_1"} />

			<div className={s.textblock}>
				<div className={s.textblockicon} >
				</div>
				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[4] }} />
				</ScrollAnimation>
			</div>

			<div className={s.ill01}>
				<Ill1 />
			</div>

			<Focus cid={0} data={data.focus} />

		</div>

		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<div className={s.sources} >
			<div className={s.sourcestitle} dangerouslySetInnerHTML={{__html: `Sources:` }} />
				{
					data.sources.map((d,i) => (
						<div
							key={`k`+i}
							className={s.source}
							dangerouslySetInnerHTML={{__html: d }}
						/>
					))
				}
		</div>
		</ScrollAnimation>

		
	</section>
)}

export default Mega0