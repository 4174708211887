import React, { useEffect } from 'react'

import * as s from "./graphs2_0.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'


const Graph2_0 = (props) => {

	const data = props.data;
	const xaxis = [1965, 1968, 1971, 1974, 1977, 1980, 1983, 1986, 1989, 1992, 1995, 1998, 2001, 2004, 2007, 2010, 2013, 2016]
	const yaxis = [40, 35, 30, 25, 20, 15]


	useEffect(() => {
		gsap.set(`.${s.ubsclip20}`, {width: `0%`, transformOrigin: `left center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.ubsclip20}`, {width: `100%`, duration: 1.5, ease: `power2.out`, stagger: 0.1});
	}, [props.fired]);


	return (
	<section className={s.graph}>

		<div className={s.legendbox}>
			<div className={s.legend2}>
				<div className={s.legendline1} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline2} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline3} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[2] }} />
			</div>
		</div>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greedy}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`y`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d + `%` }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.greedx}>
					{
						xaxis.map((d,i) => (
				            <div
				              key={`x`+i}
				              className={s.xaxe}
				            >
				            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
				            </div>
			            ))
					}
				</div>

				<svg className={s.svg} xmlns="http://www.w3.org/2000/svg" width="100%" height="330" viewBox="0 0 992 330" preserveAspectRatio="none">
					<defs>
						<clipPath id="ubsclip200">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip20} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip201">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip20} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip202">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip20} fill="#d5eaf4"/>
						</clipPath>
					</defs>
					<g clipPath="url(#ubsclip200)">
						<path d="M-292.25-159.906l44.936-25.689,21.564-5.585h21.571l21.568-8.377,26.96-5.026h12.581l26.956-14.52,12.581-7.26,15.277-12.845,14.378-5.026c0-.558,36.845,5.026,36.845,5.026l20.672-15.637,12.578-6.143L11.5-264.9l14.378-2.792,9.882-3.909h7.189l16.176-5.026H77.1L95.068-291.7l24.264-3.351,15.277-3.351c0-.558,12.581,9.494,12.581,9.494,0-.558,23.368,0,23.368,0l12.581-2.792h11.679l18.875-3.351H231.66l15.277-5.587,17.078,8.938,17.078-8.938h15.277l23.361-5.024,16.176-5.585h23.365l24.264,12.845,17.074,3.351h40.439l17.977-5.587,14.378-5.024,18.868,5.024,35.044,14.52,22.47-5.582,37.743-13.962,24.267-3.351,16.176,3.351,10.78,5.024,54.814-5.024" transform="translate(297.469 324.671)" fill="none" stroke="#ff1a66" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip201)">
						<path d="M-293.5-162.829l25.159-8.935,15.277-8.935h10.78l21.571-9.494,24.263,4.468,14.382-7.26,25.162,7.26,16.172-4.468,66.5-38.534h67.4l21.568-12.286,61.112-3.909,39.537-17.312h34.145l20.672,9.494,25.162-9.494,31.453-5.585h52.122l50.328-8.377h31.453l26.061,8.377,48.524,2.234,21.568-6.143h36.841l30.561,14.52h28.757l39.537-14.52,47.628-4.468,21.568-10.052,15.281,3.351,11.679-3.351h8.091" transform="translate(294.228 355.518)" fill="none" stroke="#ff1a66" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 4" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip202)">
						<path d="M-293.5-189.627l20.665-4.468,17.074-12.845,17.074,6.7,22.466-28.482,17.078,11.728,17.074,22.9,17.973-6.143h21.564l18.872-10.611,33.25,16.754,24.26-16.754,20.669,3.909,35.953-10.052,19.763-2.794c.007-1.115,14.382,0,14.382,0L39-194.1l27.855-2.792,12.585-6.143H99.208l21.568-13.962,18.872,6.143,35.047-8.938h37.743l45.835-5.582,64.7-18.988h22.466l17.074-6.143,19.767,16.754,12.581,22.9,17.973,7.818H436.2l19.77-22.339,17.078-3.351h18.868l19.774,11.728,15.277,33.508L562.911-194.1h20.672l20.665-22.9,23.368-2.794,15.273-3.349,16.172,3.349,16.179-8.933,19.767,31.832" transform="translate(294.228 399.628)" fill="none" stroke="#ff1a66" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="10 10" vectorEffect="non-scaling-stroke"/>
					</g>
				</svg>

			</div>
		</div>

	</section>
)}

export default Graph2_0