import React, { useEffect, useRef } from 'react'

import * as s from "./graphs5_2.module.css"

import { gsap } from "gsap/dist/gsap";

const Graph5_2 = (props) => {
	const heightList1 = useRef([]);
	const heightList2 = useRef([]);
	const ref = useRef(null);

	const data = props.data;
	// const [val, setVal] = useState(0);
	const yaxis = [200, 180, 160, 140, 120, 100, 80, 60, 40, 20, 0]

	useEffect(() => {
		ref.current.querySelectorAll(`.${s.barline1}`).forEach( el => {
			heightList1.current.push(el.style.height)
		})
		ref.current.querySelectorAll(`.${s.barline2}`).forEach( el => {
			heightList2.current.push(el.style.height)
		})

		gsap.set(`.${s.barline1}`, {height: `0%`, transformOrigin: `center center`});
		gsap.set(`.${s.barline2}`, {height: `0%`, transformOrigin: `center center`});
	}, []);

	useEffect(() => {
		if(props.fired) {
			gsap.to(`.${s.barline1}`, {height: (i) => heightList1.current[i], duration: 0.5, ease: `power4.out`, stagger: 0.025});
			gsap.to(`.${s.barline2}`, {height: (i) => heightList2.current[i], duration: 0.8, ease: `power4.out`, stagger: 0.05});
		}
	}, [props.fired]);


	return (
	<section className={s.graph} ref={ref}>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greed}>
					{
						yaxis.map((d,i) => (
							<div
								key={`k`+i}
								className={s.yaxe}
							>
								<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d }} />
							</div>
						))
					}
				</div>
				<div className={s.legend}>
					<div dangerouslySetInnerHTML={{__html: data.legend[0] }} />
					<div dangerouslySetInnerHTML={{__html: data.legend[1] }} />
				</div>
				<div className={s.bars} >
				{
					data.data.map((d,i) => (
						<div
							key={`k`+i}
							className={s.barbox}

						>
						<div className={s.baranimated}>
							<div className={s.barline1} style={{height: `${(100/200) * Math.abs(d.values[0])}%`}} >
								<div className={s.barline} />
							</div>
							<div>
								<div className={s.nm} dangerouslySetInnerHTML={{__html: d.name }} />
							</div>
							<div className={s.barline2} style={{height: `${(100/200) * Math.abs(d.values[1])}%`}} >
								<div className={s.barline} />
							</div>
						</div>
		            </div>
		          ))
				}
				</div>
				<div className={s.lineV} />
				<div className={s.labels_w} >
					<div className={s.labels} >2009-2014</div>
					<div className={s.labels} >2015-2020</div>
				</div>
			</div>
		</div>

	</section>
)}

export default Graph5_2