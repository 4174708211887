// extracted by mini-css-extract-plugin
export const graph = "graphs3_1-module--graph--311bX";
export const box = "graphs3_1-module--box--Dgneq";
export const scene = "graphs3_1-module--scene--1Hwyp";
export const greedy = "graphs3_1-module--greedy--WHZMo";
export const yaxe = "graphs3_1-module--yaxe--2MQph";
export const yaxevalue = "graphs3_1-module--yaxevalue--1VAWE";
export const greedx = "graphs3_1-module--greedx--24DOE";
export const xaxe = "graphs3_1-module--xaxe--3oFrZ";
export const xaxevalue = "graphs3_1-module--xaxevalue--3WtdQ";
export const svg = "graphs3_1-module--svg--ud3Uv";
export const ubsclip31 = "graphs3_1-module--ubsclip31--1uiav";
export const legend = "graphs3_1-module--legend--1uo1n";