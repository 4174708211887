import React, { useState, useEffect } from 'react'
import Main from '../components/main';
import Mega from '../components/mega';
import Impact from '../components/impact';
import data from '../data/data.json';
import '../styles/index.css'

import { CookiesProvider, useCookies } from 'react-cookie';

const COOK = `ubs_mega`
const COOK2 = `ubs_logo`

const IndexPage = () => {

  const [cookies, setCookie] = useCookies([COOK]);
  const [cookies2, setCookie2] = useCookies([COOK2]);
  const [firststart, setFirststart] = useState(true);
  const [part, setPartn] = useState(0);

  useEffect(() => {
    if(!cookies[COOK])
      setCookie(COOK, [false,false,false,false,false,false,false], { path: '/' }); 

    if(!cookies2[COOK2])
      setCookie2(COOK2, false, { path: '/' })
    else
      setCookie2(COOK2, true, { path: '/' })
    setFirststart(false);
  }, []);


  useEffect(() => {
    if((part > 0) && (part < 8)){
      
      let nma = cookies[COOK]
      if(!nma[part-1]){
      nma[part-1] = true;
      setCookie(COOK, nma, { path: '/' });
      }
    }
  }, [part]);


  function setPart(part){
    window.scrollTo(0, 0);
    setPartn(part);
  }



  return (
    <CookiesProvider>
      <div className={`ubs`}>

        {
          (part===0) &&
                        <Main 
                          data={data} 
                          cookies2={cookies2[COOK2]}
                          cookies={ (firststart) ? [false,false,false,false,false,false,false] : cookies[COOK] }
                          earthcookies = { (cookies[COOK]) ? cookies[COOK] : [false,false,false,false,false,false,false] }
                          setPart={setPart}
                        />
        }
        {
          ((part > 0) && (part < 8)) && 
                        <Mega 
                          data={data}
                          firststart={ firststart }
                          megaid={ part -1 }
                          cookies={ (firststart) ? [false,false,false,false,false,false,false] : cookies[COOK] }
                          setPart={setPart}
                        />
        }
        {
          (part===8) && <Impact data={data} setPart={setPart}/>
        }

      </div>
    </CookiesProvider>
)}

export default IndexPage