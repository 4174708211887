import React, { useEffect } from 'react'

import * as s from "./graphs2_2.module.css"

import { gsap } from "gsap/dist/gsap";

const Graph2_2 = (props) => {

	const data = props.data;
	const yaxis = [0, -1, -2, -3, -4, -5, -6]

	useEffect(() => {
		gsap.set(`.${s.barline}`, {height: `0%`, transformOrigin: `center center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.barline}`, {height: `100%`, duration: 0.5, ease: `power4.out`, stagger: 0.025});
	}, [props.fired]);


	return (
	<section className={s.graph}>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greed}>
					{
						yaxis.map((d,i) => (
							<div
								key={`k`+i}
								className={s.yaxe}
							>
								<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d + `%` }} />
							</div>
						))
					}
				</div>
				<div className={s.legend} dangerouslySetInnerHTML={{__html: data.legend }} />
				<div className={s.bars} >
				{
					data.data.map((d,i) => (
						<div
							key={`k`+i}
							className={s.barbox}
		            >
							<div className={s.baranimated}>
								<div style={{height: `${(100/6) * Math.abs(d.value)}%`}} >
									<div className={s.nm} dangerouslySetInnerHTML={{__html: d.name }} />
									<div className={s.barline} />
								</div>
							</div>
						</div>
					))
				}
				</div>
			</div>
		</div>

	</section>
)}

export default Graph2_2