	import React, { useEffect } from 'react'

	import * as s from "./graphs2_1.module.css"

	import { gsap } from "gsap/dist/gsap";

	const Graph2_1 = (props) => {

		const data = props.data;
		// const [val, setVal] = useState(0);
		const yaxis = [35, 30, 25, 20, 15, 10, 5, 0]

		let options = data.data.map((d,i) => ( { value: i, label: d.name } ))
		options.sort(function (a, b) {
			if (a.label > b.label) {
				return 1;
			}
			if (a.label < b.label) {
				return -1;
			}
			return 0;
		});

		// console.log(options)

		// console.log(options[val].value, options[val].label);

		useEffect(() => {
			gsap.set(`.${s.barline}`, {height: `0%`, transformOrigin: `center center`});
			gsap.set(`.${s.barline_sub}`, {opacity: 0});
		}, []);

		useEffect(() => {
			if(props.fired) {
				gsap.to(`.${s.barline}`, {height: `100%`, duration: 0.5, ease: `power4.out`, stagger: 0.025});
				gsap.to(`.${s.barline_sub}`, {opacity: 1, delay: 0.6, duration: 0.5, ease: `power4.out`, stagger: 0.025});
			}
		}, [props.fired]);


		return (
		<section className={s.graph}>

			<div className={s.box}>
				<div className={s.scene}>
					<div className={s.greed}>
						{
							yaxis.map((d,i) => (
								<div
									key={`k`+i}
									className={s.yaxe}
								>
									<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d }} />
								</div>
							))
						}
					</div>
					<div className={s.legend} >
						<div dangerouslySetInnerHTML={{__html: data.legend[0] }} />
						<div dangerouslySetInnerHTML={{__html: data.legend[1] }} />
					</div>
					<div className={s.bars} >
					{
							data.data.map((d,i) => (
								<div
									key={`k`+i}
									className={s.barbox}

								>
							<div className={s.baranimated}>
								<div style={{ position: 'relative', height: `${(100/35) * Math.abs(d.value)}%`}} >
									<div className={s.nm} dangerouslySetInnerHTML={{__html: d.name }} />
									<div className={s.barline} />
									<div className={s.barline_sub} style={{height: `${(100/d.value) * Math.abs(d.sub)}%`}} />
								</div>
							</div>
									</div>
								))
							}
					</div>
				</div>
			</div>

		</section>
	)}

	export default Graph2_1