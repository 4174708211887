import React, { useEffect, useState } from 'react'

import * as s from "./graphs1_2.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

import Select from 'react-select'

import { track } from '../../utils/index.js'

import Arrow from '../../assets/arrow2.svg'


const Graph1_2 = (props) => {

	const data = props.data;
	const [val, setVal] = useState(0);
	const xaxis = [`0`,`10,000`, `20,000`, `30,000`, `40,000`, `50,000`, `60,000`, `70,000`, `80,000`, `90,000`];
	const yaxis = [`50%`, `45%`, `40%`, `35%`, `30%`, `25%`, `20%`, `15%`, `10%`, `5%`, `0%`];

	let options = data.data.map((d,i) => ( { value: i, label: d.name } ))
	options.sort(function (a, b) {
	  if (a.label > b.label) {
	    return 1;
	  }
	  if (a.label < b.label) {
	    return -1;
	  }
	  return 0;
	});


	const customStyles = {
		control: (styles, state) => ({ 
			...styles, 
			backgroundColor: `#F5F5F5`,
			color: `white`,
			borderRadius: 0,
			border: 0,
    		boxShadow: 'none',
    		height: 46,
			padding: 0,
			cursor: 'pointer'
		}),
		option: (styles, { isSelected }) => ({
			...styles, 
			backgroundColor: isSelected ? `#4B9399` : `#F5F5F5`,
			':hover': { backgroundColor: `#ff7faa`, color: `white` },
			color: `#ff7faa`,
			borderRadius: 0,
			cursor: 'pointer'
		}),
		placeholder: styles => ({ ...styles, color: `#ff7faa` }),
		valueContainer: styles => ({ ...styles, overflow: "visible" }),
		singleValue: styles => ({ ...styles, color: `#ff7faa` }),
		input: styles => ({ ...styles, color: `#ff7faa` }),
		menu: styles => ({ ...styles, backgroundColor: `#F5F5F5`, marginTop: 0, marginBottom: 0, cursor: 'pointer' }),
		menuList: (provided, state) => ({
		   ...provided,
		   paddingTop: 0,
		   paddingBottom: 0,
		}),
	}

	useEffect(() => {
		gsap.set(`.${s.dot}`, {opacity: `0`, transformOrigin: `center center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.dot}`, {opacity: `1`, duration: 0.5, ease: `power4.out`, stagger: 0.005});
	}, [props.fired]);


	return (
	<section className={s.graph}>

		<div className={s.selectbox}>
			<Select
				instanceId="ubs-select1"
				placeholder={options.find(d => d.value === val).label}
				options={options}
				styles={customStyles}
				className={s.select}
				isSearchable={false}
				components={{IndicatorSeparator: () => null}}
				onKeyDown={(e)=>{}}
				onChange={(e)=>{
					track(7, options.find(d => d.value === e.value).label);
					setVal(e.value)
				}}
				value={options.find(d => d.value === val).label}
			/>
		</div>

		<div className={s.swipe}>
			<div className={s.swipetext} dangerouslySetInnerHTML={{__html: `Swipe to explore` }} />
			<Arrow />
		</div>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greedy}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`y`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d }} />
				            </div>
			            ))
					}
				</div>


				<div className={s.legendx}>
					<div dangerouslySetInnerHTML={{__html: data.legend[0] }} />
				</div>
				<div className={s.legendy}>
					<div dangerouslySetInnerHTML={{__html: data.legend[1] }} />
				</div>


				<div className={s.fieldbox}>
				<div className={s.fieldboxscroll}>

					<div className={s.greedx}>
						{
							xaxis.map((d,i) => (
					            <div
					              key={`x`+i}
					              className={s.xaxe}
					              data-id={i}
					            >
					            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
					            </div>
				            ))
						}
					</div>

					<div className={s.field}>
						{
							data.data.map((d,i) => (
					            <div
					              key={`d`+i}
					              className={s.dot + ((i===val)?` ${s.dot_active}`:``)}
					              style={{"left": `${(d.xval/90000)*100}%`, "top": `${(0.5-d.yval)*2*100}%`}}
					            >
					            	<div className={s.dotnm} dangerouslySetInnerHTML={{__html: d.nm }} />
					            	<div className={s.dotsq}><div /></div>
					            </div>
				            ))
						}
					</div>

				</div>
				</div>



			</div>
		</div>

	</section>
)}

export default Graph1_2