// extracted by mini-css-extract-plugin
export const graph = "graphs5_1-module--graph--qJItL";
export const table = "graphs5_1-module--table--3CKMh";
export const greedx = "graphs5_1-module--greedx--47jnq";
export const xaxe = "graphs5_1-module--xaxe--2kMjs";
export const xaxevalue = "graphs5_1-module--xaxevalue--32gwN";
export const tablebox = "graphs5_1-module--tablebox--1Khex";
export const tableline = "graphs5_1-module--tableline--FTF-C";
export const line = "graphs5_1-module--line--aownf";
export const val2 = "graphs5_1-module--val2--2iLsa";
export const val = "graphs5_1-module--val--1fiCz";
export const tabletd0 = "graphs5_1-module--tabletd0--1SZHM";
export const tabletd1 = "graphs5_1-module--tabletd1--1ofaZ";
export const tablebaranimated = "graphs5_1-module--tablebaranimated--2-dEC";
export const tablebaranimated2 = "graphs5_1-module--tablebaranimated2--10oIl";
export const tablebar0 = "graphs5_1-module--tablebar0--2o7V9";
export const tablebar1 = "graphs5_1-module--tablebar1--Na_fZ";
export const legend = "graphs5_1-module--legend--32Y0f";
export const legendline = "graphs5_1-module--legendline--2t1ca";
export const legendqube = "graphs5_1-module--legendqube--15ixS";
export const legendtxt = "graphs5_1-module--legendtxt--3eRB7";