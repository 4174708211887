import React, {useEffect} from 'react'

import * as s from "./graphs4_3.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

const Graph4_3 = (props) => {

	const data = props.data;
	const xaxis = [`$0.0bn`, `$0.5bn`,`$1.0bn`,`$1.5bn`,`$2.0bn`,`$2.5bn`,`$3.0bn`]

	useEffect(() => {
		gsap.set(`.${s.tablebaranimated}`, {width: `0%`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.tablebaranimated}`, {width: `100%`, duration: 1, ease: `power2.out`, stagger: 0.02});
	}, [props.fired]);

	return (
	<section className={s.graph}>

		<div className={s.table}>

			<div className={s.greedx}>
				{
					xaxis.map((d,i) => (
			            <div
			              key={`x`+i}
			              className={s.xaxe}
			            >
			            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
			            </div>
		            ))
				}
			</div>

			<div className={s.tablebox}>
				{
		          data.data.map((d,i) => (
		            <div
		              key={`k`+i}
		              className={s.tableline}
		            >
						<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: d.name }} />

						<div className={s.tabletd1} >
							<div className={s.tablebaranimated}>
								<div className={s.tablebar0} style={{width: `${((d.value<=3)?d.value:3)/3*100}%`}} />

								<div className={s.whiteline} data-id={i}>
									<svg xmlns="http://www.w3.org/2000/svg" width="20.102" height="20.102" viewBox="0 0 20.102 20.102">
									  <rect width="3.553" height="24.876" transform="translate(17.59) rotate(45)" fill="#fff"/>
									</svg>
								</div>

								<div className={s.tablebarvalue} data-id={i} dangerouslySetInnerHTML={{__html: `$` + d.value + `bn` }} />
							</div>
						</div>
		            </div>
		          ))
		        }
			</div>

		</div>

	</section>
)}

export default Graph4_3