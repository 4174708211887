// extracted by mini-css-extract-plugin
export const graph = "graphs4_0-module--graph--tBgN1";
export const legendup = "graphs4_0-module--legendup--2ysLe";
export const legenddown = "graphs4_0-module--legenddown--2_xlw";
export const legend2 = "graphs4_0-module--legend2--d6te2";
export const legend3 = "graphs4_0-module--legend3--2mp8x";
export const legend4 = "graphs4_0-module--legend4--2qncS";
export const box = "graphs4_0-module--box--2u-f8";
export const scene = "graphs4_0-module--scene--3rIhe";
export const greedy = "graphs4_0-module--greedy--1uFu5";
export const yaxe = "graphs4_0-module--yaxe--v4U_9";
export const yaxevalue = "graphs4_0-module--yaxevalue--15iVJ";
export const greedx = "graphs4_0-module--greedx--sqkzX";
export const xaxe = "graphs4_0-module--xaxe--e7phg";
export const xaxevalue = "graphs4_0-module--xaxevalue--Ve7p1";
export const fieldbox = "graphs4_0-module--fieldbox--19aVM";
export const fieldboxscroll = "graphs4_0-module--fieldboxscroll--3VMFh";
export const field = "graphs4_0-module--field--3-N3z";
export const dot = "graphs4_0-module--dot--1iGFf";
export const dotnm = "graphs4_0-module--dotnm--1Ynpv";
export const dotsq = "graphs4_0-module--dotsq--1bxl_";
export const dot_active = "graphs4_0-module--dot_active--d0wHe";
export const swipe = "graphs4_0-module--swipe--1ou1z";
export const swipetext = "graphs4_0-module--swipetext--1XJGt";