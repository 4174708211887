import React, { useState } from 'react'

import * as s from "./graph.module.css"

// import { withpath } from '../assets/icons.js'
// import { COLORS } from '../assets/icons.js'

import Graph00 from './graphs/graph0_0';
import Graph01 from './graphs/graph0_1';

import Graph10 from './graphs/graph1_0';
import Graph11 from './graphs/graph1_1';
import Graph12 from './graphs/graph1_2';

import Graph20 from './graphs/graph2_0';
import Graph21 from './graphs/graph2_1';
import Graph22 from './graphs/graph2_2';

import Graph30 from './graphs/graph3_0';
import Graph31 from './graphs/graph3_1';

import Graph40 from './graphs/graph4_0';
import Graph41 from './graphs/graph4_1';
import Graph42 from './graphs/graph4_2';
import Graph43 from './graphs/graph4_3';

import Graph50 from './graphs/graph5_0';
import Graph51 from './graphs/graph5_1';
import Graph52 from './graphs/graph5_2';

import Graph60 from './graphs/graph6_0';

import { Waypoint } from 'react-waypoint';


import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";


const Graph = (props) => {
	const data = props.data;
	const [fired, setFired] = useState(false);

	const isBrowser = typeof window !== "undefined"

  // if (typeof window === "undefined") {
  //   return;
  // }

	return (
		<Waypoint 
			scrollableAncestor={isBrowser ? window : null} // Build ver
			// scrollableAncestor={window} // Developer ver
			bottomOffset={"40%"} 
			fireOnRapidScroll={false}
			onEnter={(props) => {
				if(!fired){
					setFired(true);
				}
			}}
		>
			<section className={s.graph}>
				<div className={s.h1} dangerouslySetInnerHTML={{__html: data.h1 }} />
				<div className={s.line} style={{backgroundColor: props.color}} />
				<div className={s.h2} dangerouslySetInnerHTML={{__html: data.h2 }} />
				

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>

					<div className={s.d3graph}>
						{ props.nm === "0_0" && <Graph00 data={props.data} fired={fired} /> }
						{ props.nm === "0_1" && <Graph01 data={props.data} fired={fired} /> }

						{ props.nm === "1_0" && <Graph10 data={props.data} fired={fired} /> }
						{ props.nm === "1_1" && <Graph11 data={props.data} fired={fired} /> }
						{ props.nm === "1_2" && <Graph12 data={props.data} fired={fired} /> }

						{ props.nm === "2_0" && <Graph20 data={props.data} fired={fired} /> }
						{ props.nm === "2_1" && <Graph21 data={props.data} fired={fired} /> }
						{ props.nm === "2_2" && <Graph22 data={props.data} fired={fired} /> }
						{ props.nm === "3_0" && <Graph30 data={props.data} fired={fired} /> }
						{ props.nm === "3_1" && <Graph31 data={props.data} fired={fired} /> }

						{ props.nm === "4_0" && <Graph40 data={props.data} fired={fired} /> }
						{ props.nm === "4_1" && <Graph41 data={props.data} fired={fired} /> }
						{ props.nm === "4_2" && <Graph42 data={props.data} fired={fired} /> }
						{ props.nm === "4_3" && <Graph43 data={props.data} fired={fired} /> }

						{ props.nm === "5_0" && <Graph50 data={props.data} fired={fired} /> }
						{ props.nm === "5_1" && <Graph51 data={props.data} fired={fired} /> }
						{ props.nm === "5_2" && <Graph52 data={props.data} fired={fired} /> }

						{ props.nm === "6_0" && <Graph60 data={props.data} fired={fired} /> }
					</div>

				</ScrollAnimation>

				<div className={s.source} dangerouslySetInnerHTML={{__html: data.source }} />
			</section>

		</Waypoint>
)}

export default Graph