// extracted by mini-css-extract-plugin
export const graph = "graphs2_1-module--graph--2cFFp";
export const selectbox = "graphs2_1-module--selectbox--3cQTU";
export const select = "graphs2_1-module--select--109I4";
export const txt = "graphs2_1-module--txt--3u-w8";
export const box = "graphs2_1-module--box--1Lp7L";
export const scene = "graphs2_1-module--scene--uWWyk";
export const legend = "graphs2_1-module--legend--3YOQC";
export const greed = "graphs2_1-module--greed--u-BHs";
export const yaxe = "graphs2_1-module--yaxe--3ujjW";
export const yaxevalue = "graphs2_1-module--yaxevalue--2Co68";
export const bars = "graphs2_1-module--bars--7bn6x";
export const barbox = "graphs2_1-module--barbox--HxoBD";
export const nm = "graphs2_1-module--nm--2BT1q";
export const baranimated = "graphs2_1-module--baranimated--2nxE6";
export const bar = "graphs2_1-module--bar--OZ4CV";
export const barline = "graphs2_1-module--barline--1Vac9";
export const tip = "graphs2_1-module--tip--2S2N-";
export const bar_active = "graphs2_1-module--bar_active--3y_GG";
export const barline_sub = "graphs2_1-module--barline_sub--2SUrt";
export const arrow = "graphs2_1-module--arrow--1QkKK";