// extracted by mini-css-extract-plugin
export const graph = "graphs2_0-module--graph--YhWhL";
export const legendbox = "graphs2_0-module--legendbox--3zv92";
export const legend2 = "graphs2_0-module--legend2--_Ih8o";
export const legendtxt = "graphs2_0-module--legendtxt--3yAFZ";
export const legendline1 = "graphs2_0-module--legendline1--1doO2";
export const legendline2 = "graphs2_0-module--legendline2--5O7Y9";
export const legendline3 = "graphs2_0-module--legendline3--pe8dT";
export const box = "graphs2_0-module--box--2tOyY";
export const scene = "graphs2_0-module--scene--1H27r";
export const legend = "graphs2_0-module--legend--3LaSJ";
export const greedy = "graphs2_0-module--greedy--1krU5";
export const yaxe = "graphs2_0-module--yaxe--3ShMA";
export const yaxevalue = "graphs2_0-module--yaxevalue--2092S";
export const greedx = "graphs2_0-module--greedx--2VbNL";
export const xaxe = "graphs2_0-module--xaxe--1YImL";
export const xaxevalue = "graphs2_0-module--xaxevalue--2JUMn";
export const svg = "graphs2_0-module--svg--1LiA4";
export const ubsclip20 = "graphs2_0-module--ubsclip20--1GyAG";