import React, { useEffect } from 'react'

import * as s from "./mega.module.css"

import { COLORS, withpath } from '../assets/icons.js'

import Header from './header'
import More from './more'
import { track } from '../utils/index.js'
import Mega0 from './mega0'
import Mega1 from './mega1'
import Mega2 from './mega2'
import Mega3 from './mega3'
import Mega4 from './mega4'
import Mega5 from './mega5'
import Mega6 from './mega6'
import ImageFadeIn from "react-image-fade-in";

import { Link } from "gatsby"
import Globeicon from '../assets/globeicon.svg';

import Icon0 from '../assets/mega/icon0.svg';
import Icon1 from '../assets/mega/icon1.svg';
import Icon2 from '../assets/mega/icon2.svg';
import Icon3 from '../assets/mega/icon3.svg';
import Icon4 from '../assets/mega/icon4.svg';
import Icon5 from '../assets/mega/icon5.svg';
import Icon6 from '../assets/mega/icon6.svg';

import { gsap } from "gsap/dist/gsap";
import {ScrollTrigger} from 'gsap/all'

let ST

// import { useQueryParam, StringParam, NumberParam } from "use-query-params";

const Mega = (props) => {
	const data = props.data;
	const id = props.megaid;
	gsap.registerPlugin(ScrollTrigger);
	gsap.core.globals("ScrollTrigger", ScrollTrigger);

	useEffect(() => {
		ST = ScrollTrigger.create( {
		    trigger: "#ubsreturn",
		    start: "top 0px",
		    end: ()=>{
		    	// console.log(document.getElementById('ubsmega').offsetHeight - 300)
		    	return document.getElementById('ubsmega').offsetHeight - 300
		    },
		    pin: true,
		    markers: false
		})
		ST.refresh();
		ScrollTrigger.refresh();
	});

	return (
		<section className={s.section} id={`ubsmega`}>

			<div className={s.bgs}>
				<div className={s.bg}>
					<ImageFadeIn 
						width={856} 
						height={539}
						className={s.bgimg}
						src={withpath(`/img/bg.png`)}
						srcSet={withpath(`/img/bg@2x.png`) + ` 2x`}
						alt="icon"
					/>
				</div>
				<div className={s.bg_middle_left}>
					<ImageFadeIn 
						width={560} 
						height={512}
						className={s.bgimg_middle_left}
						src={withpath(`/img/bg2.png`)}
						srcSet={withpath(`/img/bg2@2x.png`) + ` 2x`}
						alt="icon"
					/>
				</div>
				<div className={s.bg_middle_right}>
					<ImageFadeIn 
						width={560} 
						height={512}
						className={s.bgimg_middle_right}
						src={withpath(`/img/bg2.png`)}
						srcSet={withpath(`/img/bg2@2x.png`) + ` 2x`}
						alt="icon"
					/>
				</div>
				<div className={s.bg_bottom}>
					<ImageFadeIn 
						width={856} 
						height={539}
						className={s.bgimg_bottom}
						src={withpath(`/img/bg.png`)}
						srcSet={withpath(`/img/bg@2x.png`) + ` 2x`}
						alt="icon"
					/>
				</div>
			</div>

			<div className={s.flex}>

				<div className={s.stikybox} id={`ubsreturn`}>
					<div 
						className={s.stikyboxblock}
						onKeyDown={()=>{}}
						role="button"
						aria-label="toglobe"
						tabIndex={0}
						onClick={()=>{
							props.setPart(0);
						}}
					>
						<div className={s.back}>
						  <div className={s.returntxt} dangerouslySetInnerHTML={{__html: data.return }} />
						  <Globeicon />
						</div>
					</div>
				</div>

				<Header data={data} type={`mega`}/>

					<div className={s.title}>
						
						<div className={s.titleflex}>
							<div className={s.titleflextxt}>
								<h1 className={s.h1} style={{color: COLORS[id] }} dangerouslySetInnerHTML={{__html: data.megatrends[id].h1 }} />
								<h2 className={s.h2} dangerouslySetInnerHTML={{__html: data.megatrends[id].h2 }} />
								<h3 className={s.h3} dangerouslySetInnerHTML={{__html: data.megatrends[id].h3 }} />
							</div>
							<div className={s.titlefleximg}>

						  		{id===0 && <Icon0 className={s.icon + ` ubsicon${id}`}/>}
						  		{id===1 && <Icon1 className={s.icon + ` ubsicon${id}`}/>}
						  		{id===2 && <Icon2 className={s.icon + ` ubsicon${id}`}/>}
						  		{id===3 && <Icon3 className={s.icon + ` ubsicon${id}`}/>}
						  		{id===4 && <Icon4 className={s.icon + ` ubsicon${id}`}/>}
						  		{id===5 && <Icon5 className={s.icon + ` ubsicon${id}`}/>}
						  		{id===6 && <Icon6 className={s.icon + ` ubsicon${id}`}/>}

							</div>
						</div>

						<div className={s.line} style={{backgroundColor: COLORS[id] }} />

					</div>
				

		  		{id===0 && <Mega0 data={data.megatrends[0]}/>}
		  		{id===1 && <Mega1 data={data.megatrends[1]}/>}
		  		{id===2 && <Mega2 data={data.megatrends[2]}/>}
		  		{id===3 && <Mega3 data={data.megatrends[3]}/>}
		  		{id===4 && <Mega4 data={data.megatrends[4]}/>}
		  		{id===5 && <Mega5 data={data.megatrends[5]}/>}
		  		{id===6 && <Mega6 data={data.megatrends[6]}/>}


					<More 
						data={data}
						cookies={props.cookies} 
						explore={data.explore} 
						cid={id}
						setPart={props.setPart} 
					/>
				
			</div>

		</section>
	)
}

export default Mega