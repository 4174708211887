import React, { useEffect } from 'react'

import * as s from "./graphs3_0.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'


const Graph3_0 = (props) => {

	const data = props.data;
	const xaxis = [2015, 2016, 2017, 2018, 2019, 2020]
	const yaxis = [110, 105, 100, 95, 90, 85, 80]


	useEffect(() => {
		gsap.set(`.${s.ubsclip30}`, {width: `0%`, transformOrigin: `left center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.ubsclip30}`, {width: `100%`, duration: 1.5, ease: `power2.out`, stagger: 0.1});
	}, [props.fired]);


	return (
	<section className={s.graph}>

		<div className={s.legendbox}>
			<div className={s.legend2}>
				<div className={s.legendline1} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline2} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
			</div>
		</div>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greedy}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`y`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d + `%` }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.greedx}>
					{
						xaxis.map((d,i) => (
				            <div
				              key={`x`+i}
				              className={s.xaxe}
				            >
				            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.legend}>
					<div dangerouslySetInnerHTML={{__html: data.legend[2] }} />
					<div dangerouslySetInnerHTML={{__html: data.legend[3] }} />
				</div>
				<div className={s.legend3}>
					<div dangerouslySetInnerHTML={{__html: `Cumulative Return` }} />
				</div>
				<svg className={s.svg} xmlns="http://www.w3.org/2000/svg" width="100%" height="330" viewBox="0 0 950 330" preserveAspectRatio="none">
					<defs>
						<clipPath id="ubsclip300">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip30} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip301">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip30} fill="#d5eaf4"/>
						</clipPath>
					</defs>
					<g clipPath="url(#ubsclip300)">
						<path d="M16.9,113.8l29.6,56.5l16.1-14l17.7-6.8l15.3,16.3l16.7-9.5l13.2,31l15.6,9.9l17.5-19l30.1,28.9l16.6-19.8l15.7,13.9l16.3-13.9h17.1l14.8-9.1l18.8,23l12.3,19.5l28.2-27.1l34.1-3.2l16.8-16.2h16.3l14.5-14.2l15.9-3.6l16.2,3.6l16-23.4l17.5,8.3l30.5-27.6h16.8l14.3-3.4l17.3,7l14.4-3.6l12.8,19.3l19,16l14.7-7.7h17.2l14.2,21l15.4-21l17.6-11.9l13.8,8h22.6l12.3-4.4l15.7,16l16.2,7.4l15.6,26.5l16.6,14.7l12.9,4.5l17.2,22.9l16.2,4.2l12.8,16.8h19.5l11.8,12.3l34.9-37.7l13.4,12h16.6" fill="none" stroke="#1ABCFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="1 4" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip301)">
						<path d="M0.4,104.6l29.2,13.1l33.1-6.4l16.9,11.5l14.8,6.4h16.3l31.2-34.1l15.6-10.2l17.8,4.2l14.8,15.5l21.8-9.5l7.8-10.2l16.1,10.2L259,84.9h8.8l16.1,44.4l31.8-17.9l14.8,6.4l16.1-32.8l33.3-28.4l13.8,22.8l18.8-8.4L426.9,51l16.4,5.5H457l16.7,22.8H493l28.7-15.8L536.9,51h16.2l15.2,12.5l12.2,7.4h7.6l12-7.4l15.7,7.4l31.7-7.4l15.7,11.9l16.2-37.6L694.9,51h17.8l28.5,12.5L755.8,51h16.1l15.2,12.5l17.7-3.2l16.2,10.6h17.2l14.5,14l17.7-9.4l14.5,3.8l16.3-8.4l30.9,22.6h17.9" fill="none" stroke="#1ABCFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
					</g>
				</svg>

			</div>
		</div>

	</section>
)}

export default Graph3_0