// extracted by mini-css-extract-plugin
export const graph = "graphs4_3-module--graph--138ZN";
export const table = "graphs4_3-module--table--1l-DF";
export const greedx = "graphs4_3-module--greedx--Umm4s";
export const xaxe = "graphs4_3-module--xaxe--14-Mk";
export const xaxevalue = "graphs4_3-module--xaxevalue--2wvn-";
export const tablebox = "graphs4_3-module--tablebox--WfH2O";
export const tableline = "graphs4_3-module--tableline--EkK3a";
export const tabletd0 = "graphs4_3-module--tabletd0--3M_Zc";
export const tabletd1 = "graphs4_3-module--tabletd1--1jSSW";
export const tablebaranimated = "graphs4_3-module--tablebaranimated--Sgq7b";
export const tablebar0 = "graphs4_3-module--tablebar0--2TCPY";
export const tablebarvalue = "graphs4_3-module--tablebarvalue--1frHp";
export const whiteline = "graphs4_3-module--whiteline--XFf90";