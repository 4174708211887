import React, { useEffect } from 'react'

import * as s from "./graphs1_0.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'


const Graph1_0 = (props) => {

	const data = props.data;
	const xaxis = [2014, 2015, 2016, 2017, 2018, 2019, 2020]
	const yaxis = [35, 30, 25, 20, 15, 10, 5, 0]


	useEffect(() => {
		gsap.set(`.${s.ubsclip10}`, {width: `0%`, transformOrigin: `left center`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.ubsclip10}`, {width: `100%`, duration: 1.5, ease: `power2.out`, stagger: 0.1});
	}, [props.fired]);


	return (
	<section className={s.graph}>


		<div className={s.legendbox}>
			<div className={s.legend2}>
				<div className={s.legendline0} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline1} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline2} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[2] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline3} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[3] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline4} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[4] }} />
			</div>
			<div className={s.legend2}>
				<div className={s.legendline5} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[5] }} />
			</div>
		</div>


		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greedy}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`y`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d + `%` }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.greedx}>
					{
						xaxis.map((d,i) => (
				            <div
				              key={`x`+i}
				              className={s.xaxe}
				            >
				            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.legend} data-id={0} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
				<div className={s.legend} data-id={1} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
				<div className={s.legend} data-id={2} dangerouslySetInnerHTML={{__html: data.legend[2] }} />
				<div className={s.legend} data-id={3} dangerouslySetInnerHTML={{__html: data.legend[3] }} />
				<div className={s.legend} data-id={4} dangerouslySetInnerHTML={{__html: data.legend[4] }} />
				<div className={s.legend} data-id={5} dangerouslySetInnerHTML={{__html: data.legend[5] }} />

				<svg className={s.svg} xmlns="http://www.w3.org/2000/svg" width="100%" height="330" viewBox="0 0 992 330" preserveAspectRatio="none">
					<defs>
						<clipPath id="ubsclip100">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip10} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip101">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip10} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip102">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip10} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip103">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip10} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip104">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip10} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip105">
							<rect x="0" y="0" width="100%" height="330" className={s.ubsclip10} fill="#d5eaf4"/>
						</clipPath>
					</defs>
					<g clipPath="url(#ubsclip100)">
						<path d="M3595,93.993l17.393-3.384h14.713l13.385-2.82h22.745l12.042-5.64,12.042-2.82h33.443l18.731-6.768h34.793l13.379-4.512,17.394-5.076,14.717-2.82,10.705,2.82,30.772-2.82,20.068-7.332,9.367-3.384h44.146l21.408-6.768c0-.564,9.365-4.512,9.365-4.512h32.116l13.38-2.82h16.056l8.027-3.384,14.718-2.82h30.773l17.393-3.948c-1.338,0,13.38-2.82,13.38-2.82l16.051-3.948h29.435l13.385-5.076h12.041S4194.4,8.266,4201.1,6.574a228.416,228.416,0,0,1,26.757-4.512c8.506-.909,13.379,0,13.379,0A47.18,47.18,0,0,1,4250.6.653c6.739-.555,19.831-2.059,26.758-3.948a103.522,103.522,0,0,0,13.381-4.794c-.672,0,8.7-3.1,8.7-3.1s14.074-3.613,20.066-6.486,10.7-5.358,10.7-5.358l10.706-1.692,26.759-4.512c-.668,0,12.711-1.974,12.711-1.974h10.035l6.688-1.974,18.731-7.05c0,.282,12.713-3.948,12.713-3.948-.67.282,12.041,0,12.041,0h14.049l25.421-18.33L4493.437-69" 
						transform="translate(-3593.761 107.644)" fill="none" stroke="#D66E90" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip101)">
						<path d="M3595,107.667l8.69-2.538h6.685l8.693,1.128h5.349l7.356-2.538c0,.282,6.689-1.41,6.689-1.41h22.066l8.693-1.974c-.669,0,3.8,3.061,14.042,1.974s16.049-1.974,16.049-1.974h8.692l21.4-5.076h9.359l6.021,2.538c-.668-.282,6.687,0,6.687,0h6.688l14.038-2.538,15.384,1.128,8.024-3.666h7.354l6.687,1.128c0-.282,7.358,0,7.358,0h7.355s9.606,1.052,16.717-1.128,13.374-3.384,13.374-3.384l6.684-1.41h6.019l11.37-4.23,14.043,4.23h12.7l14.717-6.2c-.672,0,10.029-2.256,10.029-2.256h18.052l6.021-1.41,18.055-6.2,7.355-2.82h8.021l20.063-3.948h8.021l8.025,3.948h6.687l8.7-2.256,7.353-1.692,7.358,3.948h4.679l18.057-3.948,11.368-3.948h12.705l11.368-1.692h16.728l8.014-1.41,14.712,1.41,11.367-1.41,4.682-2.82h6.015l5.352-1.41,6.019-1.41h20.062l10.7-4.794h11.368l8.024,1.128,10.7-1.128,8.024,1.128,16.717-1.128h13.375l8.689-1.41,10.7-1.41,6.689-1.41h6.687l12.705-1.692h10.03l6.685,1.692,6.687,1.41,8.025-3.1,6.689-1.692c-.669,0,4.679,1.692,4.679,1.692l8.026-3.1,6.687-3.666,6.688,1.974,18.723,3.1,8.69,1.692,5.35,1.692,4.682,2.82,14.714-13.254,11.366,3.948h12.037l15.378-29.327c0,.282,0-23.124,0-23.124l4.684-20.022c-.672,0,4.678-14.664,4.678-14.664" 
						transform="translate(-3593.761 110.044)" fill="none" stroke="#D66E90" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="10 10" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip102)">
						<path d="M3596.5,119.419l17.7-3.1s34.849-4.578,42.193-5.358,25.861-3.1,25.861-3.1l44.235-5.076,35.389-9.588s40.156-6.723,51.042-7.614,26.541-3.1,26.541-3.1c-.68.282,15.652-1.692,15.652-1.692l27.222-2.82,11.567,2.82s13.589-7.43,21.78-8.742,19.735-3.948,19.735-3.948l13.61-2.538,27.9-4.512,12.931-1.974,35.389-3.666,14.291-6.768h10.89l26.541-6.768c-.684,0,13.611-5.076,13.611-5.076l8.167-2.82s25.321-5.288,33.346-5.358,17.694,0,17.694,0l14.289-3.666c0,.282,8.851-5.64,8.851-5.64l15.652-14.382h32.664s21.646-10.3,28.585-10.152,22.458-1.974,22.458-1.974c-.68,0,29.4-1.941,36.067-1.974s19.056,0,19.056,0l17.016,3.948,17.012-3.948s18.871-7.819,27.905-9.588,19.735-2.82,19.735-2.82l17.693-1.692,31.307-1.692c-.684,0,12.249-18.612,12.249-18.612-.684,0,11.43-9.019,23.211-9.7" 
						transform="translate(-3593.57 109.852)" fill="none" stroke="#272a32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="1 4" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip103)">
						<path d="M3595,116.712l18.849-1.691,11.447,1.691,12.8-1.691,9.427-2.82s17.376,2.434,27.607,1.128,19.524-2.538,19.524-2.538c0,.282,9.431,0,9.431,0s26.344-.88,33.667-1.692,16.834-1.692,16.834-1.692h22.22s28.258,1,35.688,0,18.854-2.538,18.854-2.538h10.1s26.1-1.657,34.34-2.256,40.943-1.862,47.809-2.538,16.834-1.41,16.834-1.41h14.813s45.06-2.118,53.192-2.538,14.146,1.849,22.224,0,40.4-4.23,40.4-4.23c-.673,0,18.182-1.41,18.182-1.41s18.077.268,25.586,1.41,16.835,1.692,16.835,1.692c-.674,0,8.077-1.692,8.077-1.692l12.8-2.82s21.014,1.778,30.975,0a137.918,137.918,0,0,1,18.854-1.974c-.676-.282,8.078,0,8.078,0l6.732,1.974,14.817-3.384h22.894l10.1-1.974h20.2l8.754-2.256,8.751,7.614,18.854-10.152h20.874s6.795,2.5,16.837,0,11.91-3.711,18.853-3.666,28.955,0,28.955,0L4427.26,73l10.1,2.256h8.756L4461.6,73h8.754l18.854-13.254" 
						transform="translate(-3593.761 124.121)" fill="none" stroke="#D66E90" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="1 4" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip104)">
						<path d="M3700,146.455h21.446l11.7,1.41s16.666.673,24.046,0,15.6-1.41,15.6-1.41h9.1l12.349-3.666h20.8l16.9-4.512s14.25,2.383,24.7,2.256,16.9,0,16.9,0h35.095l11.047-2.256h35.1l12.348-2.82s16.122,4.479,26,2.82,17.549-2.82,17.549-2.82h9.1s14.095.738,22.746,0,25.347-1.692,25.347-1.692h16.9l10.4-4.512,9.1,1.692,7.8,5.64a96.883,96.883,0,0,1,17.547-5.64c8.784-1.754,14.3,0,14.3,0h9.1s-1.4.335,5.2,0,8.446-1.692,8.446-1.692.316-5.042,9.1-5.358,19.5,0,19.5,0h22.095l16.247,2.256h16.248l15.6-2.256s3.15,6.023,8.448,2.256,9.1-7.05,9.1-7.05l5.2-3.1" 
						transform="translate(-3393.563 131.32)" fill="none" stroke="#272a32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="10 10" vectorEffect="non-scaling-stroke"/>
					</g>
					<g clipPath="url(#ubsclip105)">
						<path d="M3807.75,145.5s6.414,5.785,13.146,5.358,15.021-3.384,15.021-3.384h24.411s16.452-1.2,23.785-1.974,14.394-6.2,14.394-6.2l15.023-2.538,12.517-14.382c0-.282,11.348,24.883,21.283,23.124s15.646-3.1,15.646-3.1-1.02-2.841,8.765-3.1,18.778,0,18.778,0h20.029l11.889-1.41,15.649-12.126,9.388,7.9,10.641-11.28,24.408,15.51s7.14-14.515,11.9-18.33,8.137-6.768,8.137-6.768v-5.641l11.266-67.4" 
						transform="translate(-3214.992 121.561)" fill="none" stroke="#272a32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" vectorEffect="non-scaling-stroke"/>
					</g>
				</svg>

			</div>
		</div>

	</section>
)}

export default Graph1_0