import React from 'react'

import * as s from "./mega.module.css"

import { COLORS } from '../assets/icons.js'

import Graph from "./graph"
import Focus from "./focus"

import Ill0 from '../assets/ill4_0.svg';
import Ill1 from '../assets/ill4_1.svg';

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";



const Mega4 = (props) => {

	const data = props.data;

	return (
	<section className={s.mega4}>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
</ScrollAnimation>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[2] }} />
</ScrollAnimation>
		<div className={s.lineblock}>
			<div className={s.lineblockline} style={{backgroundColor:COLORS[4]}}/>
			<Graph color={COLORS[4]} data={data.graph1} nm={"4_0"}/>

			<div className={s.textblock}>
				<div className={s.textblockicon} >
					<Ill0 />
				</div>
				<div>
									<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[3] }} />
					</ScrollAnimation>
										<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[4] }} />
					</ScrollAnimation>
										<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[5] }} />
					</ScrollAnimation>
				</div>
			</div>

			<div className={s.graphtext}>
				<Graph color={COLORS[4]} data={data.graph2} nm={"4_1"}/>
				<div className={s.gtb}>
									<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.pt} dangerouslySetInnerHTML={{__html: data.txt[6] }} />
					</ScrollAnimation>
				</div>
			</div>

			<div className={s.textblock}>
				<div className={s.textblockicon} >
				</div>
				<div>
									<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[7] }} />
					</ScrollAnimation>
										<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[8] }} />
					</ScrollAnimation>
				</div>
			</div>

			<Graph color={COLORS[4]} data={data.graph3} nm={"4_2"}/>

			<div className={s.textblock}>
				<div className={s.textblockicon} >
					<Ill1 />
				</div>
				<div className={s.textblock4}>
									<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[9] }} />
					</ScrollAnimation>
										<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[10] }} />
					</ScrollAnimation>
				</div>
			</div>

			<Graph color={COLORS[4]} data={data.graph4} nm={"4_3"} />

			<div className={s.textblock}>
				<div className={s.textblockicon} >
				</div>
				<div>
									<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[11] }} />
					</ScrollAnimation>
				</div>
			</div>

			<Focus cid={4} data={data.focus} />

		</div>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					
		<div className={s.sources} >
			<div className={s.sourcestitle} dangerouslySetInnerHTML={{__html: `Sources:` }} />
				{
					data.sources.map((d,i) => (
						<div
							key={`k`+i}
							className={s.source}
							dangerouslySetInnerHTML={{__html: d }}
						/>
					))
				}
		</div>
</ScrollAnimation>



	</section>
)}

export default Mega4