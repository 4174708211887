import React, {useEffect} from 'react'

import * as s from "./graphs1_1.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

const Graph1_1 = (props) => {

	const data = props.data;
	const xaxis = [-10, 0, 10, 20, 30, 40, 50]

	useEffect(() => {
		gsap.set(`.${s.tablebaranimated}`, {width: `0%`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.tablebaranimated}`, {width: `83%`, duration: 1, ease: `power1.out`, stagger: 0.025});
	}, [props.fired]);

	return (
	<section className={s.graph} data-id={`1_1`}>

		<div className={s.table}>

			<div className={s.greedx}>
				{
					xaxis.map((d,i) => (
			            <div
			              key={`x`+i}
			              className={s.xaxe}
			            >
			            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d + `%` }} />
			            </div>
		            ))
				}
			</div>

			<div className={s.tablebox}>
				{
		          data.data.map((d,i) => (
		            <div
		              key={`k`+i}
		              className={s.tableline}
		            >
						<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: d.name }} />

						<div className={s.tabletd1} >
							<div className={s.tablebaranimated}>
								<div className={s.tablebar0} style={{width: `${Math.abs(d.commerce)*2}%`}} />
							</div>
							<div className={s.tablebaranimated + ((d.stores<0)?` ${s.tablebaranimated2}`:``)} >
								<div className={s.tablebar1} style={{width: `${Math.abs(d.stores)*2}%`}} />
							</div>
						</div>
		            </div>
		          ))
		        }
			</div>

			<div className={s.legend} >
				<div className={s.legendline}> 
					<div className={s.legendqube} data-id={0} />
					<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
				</div>
				<div className={s.legendline}>
					<div className={s.legendqube} data-id={1} />
					<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
				</div>
			</div>

		</div>

	</section>
)}

export default Graph1_1