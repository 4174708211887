// extracted by mini-css-extract-plugin
export const section = "mega-module--section--ONlON";
export const mega0 = "mega-module--mega0--2tFrE";
export const mega1 = "mega-module--mega1--2BVCn";
export const mega2 = "mega-module--mega2--1BK52";
export const mega3 = "mega-module--mega3--JUTYV";
export const mega4 = "mega-module--mega4--3Uc3Z";
export const mega5 = "mega-module--mega5--1Ggtn";
export const mega6 = "mega-module--mega6--3_jnx";
export const flex = "mega-module--flex--3Z66l";
export const title = "mega-module--title--1VDWF";
export const titleflex = "mega-module--titleflex--2hmJF";
export const titleflextxt = "mega-module--titleflextxt--ujpMO";
export const titlefleximg = "mega-module--titlefleximg--o3B_1";
export const h1 = "mega-module--h1--2PT8y";
export const h2 = "mega-module--h2--V8S2Z";
export const h3 = "mega-module--h3--2brgI";
export const icon = "mega-module--icon--38dB3";
export const line = "mega-module--line--2JlsP";
export const p = "mega-module--p--KafRE";
export const textblock4 = "mega-module--textblock4--2JZ78";
export const graphtext = "mega-module--graphtext--wHBMj";
export const gtb = "mega-module--gtb--1YyeL";
export const pt = "mega-module--pt--pfjdt";
export const gtb2 = "mega-module--gtb2--13XpY";
export const ptt = "mega-module--ptt--1fDU8";
export const p6 = "mega-module--p6--19osZ";
export const lineblock = "mega-module--lineblock--2DlXC";
export const lineblockline = "mega-module--lineblockline--1aRUK";
export const textblock = "mega-module--textblock--2SXmU";
export const textblockicon = "mega-module--textblockicon--3GOaE";
export const textblocktext = "mega-module--textblocktext--3z1Wf";
export const sources = "mega-module--sources--36fNU";
export const sourcestitle = "mega-module--sourcestitle--1S7YR";
export const source = "mega-module--source--fnZKA";
export const ill01 = "mega-module--ill01--14MyK";
export const ill31 = "mega-module--ill31--31O1o";
export const ill51 = "mega-module--ill51--129aP";
export const bgs = "mega-module--bgs--1rFS0";
export const bg = "mega-module--bg--3BxhW";
export const bgimg = "mega-module--bgimg--26ZYN";
export const bg_middle_left = "mega-module--bg_middle_left--2iJQ-";
export const bgimg_middle_left = "mega-module--bgimg_middle_left--3Ly-_";
export const bg_middle_right = "mega-module--bg_middle_right--3GWlN";
export const bgimg_middle_right = "mega-module--bgimg_middle_right--3eNiS";
export const bg_bottom = "mega-module--bg_bottom--4b1le";
export const bgimg_bottom = "mega-module--bgimg_bottom--3DXtz";
export const stikybox = "mega-module--stikybox--2vKDg";
export const stikyboxblock = "mega-module--stikyboxblock--1iKiQ";
export const back = "mega-module--back--1LI17";
export const returntxt = "mega-module--returntxt--3AzIH";