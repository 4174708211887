// extracted by mini-css-extract-plugin
export const graph = "graphs4_2-module--graph--1fZwq";
export const table = "graphs4_2-module--table--iUfDu";
export const greedx = "graphs4_2-module--greedx--ogx0i";
export const xaxe = "graphs4_2-module--xaxe--N8CXy";
export const xaxevalue = "graphs4_2-module--xaxevalue--tcXSa";
export const tablebox = "graphs4_2-module--tablebox--2LALN";
export const tableline = "graphs4_2-module--tableline--3OAga";
export const tabletd0 = "graphs4_2-module--tabletd0--3IHIG";
export const tabletd1 = "graphs4_2-module--tabletd1--2cYDP";
export const tablebaranimated = "graphs4_2-module--tablebaranimated--3FtC1";
export const tablebar0 = "graphs4_2-module--tablebar0--iFvrI";
export const tablebarvalue = "graphs4_2-module--tablebarvalue--2giRy";
export const whiteline = "graphs4_2-module--whiteline--10yTu";