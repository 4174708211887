// extracted by mini-css-extract-plugin
export const graph = "graphs5_0-module--graph--UCNZQ";
export const selectbox = "graphs5_0-module--selectbox--3c7v5";
export const select = "graphs5_0-module--select--1yLCd";
export const txt = "graphs5_0-module--txt--235Wu";
export const box = "graphs5_0-module--box---FZkL";
export const scene = "graphs5_0-module--scene--2tQEu";
export const legend = "graphs5_0-module--legend--2ppdT";
export const greed = "graphs5_0-module--greed--M9FqQ";
export const g50_w = "graphs5_0-module--g50_w--1ado2";
export const g50line = "graphs5_0-module--g50line--3JrYU";
export const g50barlist = "graphs5_0-module--g50barlist--3CZiH";
export const g50xline = "graphs5_0-module--g50xline--2BeEH";
export const yaxe = "graphs5_0-module--yaxe--3pSor";
export const yaxevalue = "graphs5_0-module--yaxevalue--3dgMB";
export const yaxevalue2 = "graphs5_0-module--yaxevalue2--13oM9";
export const percents0 = "graphs5_0-module--percents0--1y5ld";
export const percents1 = "graphs5_0-module--percents1--2JTgY";
export const bars = "graphs5_0-module--bars--1veVv";
export const svg = "graphs5_0-module--svg--396Im";
export const barbox2 = "graphs5_0-module--barbox2--1Ex9Q";
export const barbox = "graphs5_0-module--barbox--1pppN";
export const nm = "graphs5_0-module--nm--2TRnK";
export const baranimated = "graphs5_0-module--baranimated--3CW9z";
export const bar = "graphs5_0-module--bar--3339o";
export const barline1 = "graphs5_0-module--barline1--n9PaD";
export const tip = "graphs5_0-module--tip--1nmp-";
export const bar_active = "graphs5_0-module--bar_active--2Tfgx";
export const point_w = "graphs5_0-module--point_w--16AYt";
export const point = "graphs5_0-module--point--2Uy06";
export const pointName = "graphs5_0-module--pointName--1Z2VG";
export const pointLine = "graphs5_0-module--pointLine--36HRg";
export const barline2 = "graphs5_0-module--barline2--1l05d";
export const arrow = "graphs5_0-module--arrow--1PHpJ";