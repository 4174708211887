 import React, { useEffect } from 'react'

import * as s from "./graphs3_1.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'


const Graph3_1 = (props) => {

	// const data = props.data;
	const xaxis = [`1Q18`, `2Q18`, `3Q18`, `4Q18`, `1Q19`, `2Q19`, `3Q19`, `4Q19`, `1Q20`, `2Q20*`]
	const yaxis = [`18,000`, `16,000`, `14,000`, `12,000`, `10,000`, `8,000`, `6,000`, `4,000`, `2,000`, `0`, `-2,000`]


	useEffect(() => {

		gsap.set(`.${s.ubsclip31}`, {height: `0%`, scaleY: -1, transformOrigin: `0% 0%`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.ubsclip31}`, {height: `100%`, duration: 1.0, ease: `power2.out`, stagger: 0.05});
	}, [props.fired]);


	return (
	<section className={s.graph}>

		<div className={s.box}>
			<div className={s.scene}>
				<div className={s.greedy}>
					{
						yaxis.map((d,i) => (
				            <div
				              key={`y`+i}
				              className={s.yaxe}
				            >
				            	<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d }} />
				            </div>
			            ))
					}
				</div>
				<div className={s.greedx}>
					{
						xaxis.map((d,i) => (
				            <div
				              key={`x`+i}
				              className={s.xaxe}
				            >
				            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
				            </div>
			            ))
					}
				</div>


				<svg className={s.legend} xmlns="http://www.w3.org/2000/svg" width="273" height="86" viewBox="0 0 273 86">
				  <g transform="translate(1200 -1379)">
				    <text transform="translate(-1178 1395)" fill="#242830" fontSize="18" fontFamily="Metric-Medium, Metric" fontWeight="500" letterSpacing="0.02em"><tspan x="0" y="0">Europe</tspan></text>
				    <text transform="translate(-1178 1427)" fill="#242830" fontSize="18" fontFamily="Metric-Medium, Metric" fontWeight="500" letterSpacing="0.02em"><tspan x="0" y="0">USA</tspan></text>
				    <text transform="translate(-1178 1461)" fill="#242830" fontSize="18" fontFamily="Metric-Medium, Metric" fontWeight="500" letterSpacing="0.02em"><tspan x="0" y="0">Japan</tspan></text>
				    <text transform="translate(-1029 1395)" fill="#242830" fontSize="18" fontFamily="Metric-Medium, Metric" fontWeight="500" letterSpacing="0.02em"><tspan x="0" y="0">Asia ex-Japan</tspan></text>
				    <text id="Others" transform="translate(-1029 1427)" fill="#242830" fontSize="18" fontFamily="Metric-Medium, Metric" fontWeight="500" letterSpacing="0.02em"><tspan x="0" y="0">Others</tspan></text>
				    <rect width="10" height="10" transform="translate(-1200 1382)" fill="#1abcff"/>
				    <g transform="translate(-1200 1416)" fill="#fff" stroke="#1abcff" strokeWidth="1">
				      <rect width="10" height="10" stroke="none"/>
				      <rect x="0.5" y="0.5" width="9" height="9" fill="none"/>
				    </g>
				    <path d="M0,0H10V10H0Z" transform="translate(-1200 1450)" fill="#006f9b"/>
				    <rect width="10" height="10" transform="translate(-1053 1382)" fill="#86b22d"/>
				    <rect width="10" height="10" transform="translate(-1053 1416)" fill="#006f9b"/>
				  </g>
				</svg>




				<svg className={s.svg} xmlns="http://www.w3.org/2000/svg" width="100%" height="363" viewBox="0 0 958 363" preserveAspectRatio="none">
	
					<defs>
						<clipPath id="ubsclip310">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip311">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip312">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip313">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip314">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip315">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip316">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip317">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip318">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
						<clipPath id="ubsclip319">
							<rect x="0" y="363" width="100%" height="363" className={s.ubsclip31} fill="#d5eaf4"/>
						</clipPath>
					</defs>

					<g clipPath="url(#ubsclip319)">
						<rect x="882.7" y="253.6" fill="#1ABCFF" width="65" height="74"/>
						<g transform="translate(-707.039 1662)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="65" height="108"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="64" height="107"/>
						</g>
						<path fill="#006F9B" d="M882.7,138.6h64.8v7h-64.8V138.6z"/>
						<rect x="882.7" y="327.6" fill="#86B22D" width="65" height="4"/>
						<rect x="882.7" y="134.6" fill="#008A98" width="65" height="4"/>
					</g>
					<g clipPath="url(#ubsclip318)">
						<rect x="787.7" y="210.6" fill="#1ABCFF" width="64" height="117"/>
						<g transform="translate(-802.039 1581)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="64" height="146"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="63" height="145"/>
						</g>
						<rect x="787.7" y="48.6" fill="#006F9B" width="64" height="16"/>
						<rect x="787.7" y="36.6" fill="#86B22D" width="64" height="12"/>
						<rect x="787.7" y="22.6" fill="#008A98" width="64" height="14"/>
					</g>
					<g clipPath="url(#ubsclip317)">
						<rect x="685.7" y="203.6" fill="#1ABCFF" width="66" height="124"/>
						<g transform="translate(-904.039 1681)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="66" height="40"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="65" height="39"/>
						</g>
						<rect x="685.7" y="159.6" fill="#006F9B" width="66" height="5"/>
						<rect x="685.7" y="155.6" fill="#86B22D" width="66" height="4"/>
						<rect x="685.7" y="151.6" fill="#008A98" width="66" height="4"/>
					</g>
					<g clipPath="url(#ubsclip316)">
						<rect x="592.7" y="238.6" fill="#1ABCFF" width="65" height="89"/>
						<g transform="translate(-997.039 1699)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="65" height="56"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="64" height="55"/>
						</g>
						<rect x="592.7" y="176.6" fill="#86B22D" width="65" height="6"/>
						<rect x="592.7" y="172.6" fill="#006F9B" width="65" height="4"/>
					</g>
					<g clipPath="url(#ubsclip315)">
						<rect x="498.7" y="269.6" fill="#1ABCFF" width="64" height="58"/>
						<g transform="translate(-1091.039 1735)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="64" height="51"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="63" height="50"/>
						</g>
						<rect x="498.7" y="195.6" fill="#006F9B" width="64" height="23"/>
						<rect x="498.7" y="186.6" fill="#86B22D" width="64" height="9"/>
						<rect x="498.7" y="185.6" fill="#008A98" width="64" height="1"/>
					</g>
					<g clipPath="url(#ubsclip314)">
						<rect x="401.7" y="286.6" fill="#1ABCFF" width="65" height="41"/>
						<g transform="translate(-1188.039 1773)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="65" height="30"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="64" height="29"/>
						</g>
						<rect x="401.7" y="240.6" fill="#006F9B" width="65" height="16"/>
						<rect x="401.7" y="238.6" fill="#008A98" width="65" height="2"/>
					</g>
					<g clipPath="url(#ubsclip313)">
						<rect x="305.7" y="307.6" fill="#1ABCFF" width="65" height="20"/>
						<g transform="translate(-1284.039 1802)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="65" height="22"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="64" height="21"/>
						</g>
						<rect x="305.7" y="327.6" fill="#4C92B3" width="65" height="11"/>
						<rect x="305.7" y="281.6" fill="#008A98" width="65" height="4"/>
					</g>
					<g clipPath="url(#ubsclip312)">
						<rect x="212.7" y="318.6" fill="#1ABCFF" width="66" height="9"/>
						<g transform="translate(-1377.039 1823)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="66" height="12"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="65" height="11"/>
						</g>
						<rect x="212.7" y="283.6" fill="#006F9B" width="66" height="23"/>
						<rect x="212.7" y="281.6" fill="#86B22D" width="66" height="2"/>
						<rect x="212.7" y="277.6" fill="#008A98" width="66" height="4"/>
					</g>
					<g clipPath="url(#ubsclip311)">
						<rect x="113.7" y="322.6" fill="#1ABCFF" width="65" height="5"/>
						<g transform="translate(-1476.039 1827)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="65" height="12"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="64" height="11"/>
						</g>
						<rect x="113.7" y="294.6" fill="#006F9B" width="65" height="17"/>
						<rect x="113.7" y="293.6" fill="#86B22D" width="65" height="1"/>
						<rect x="113.7" y="289.6" fill="#008A98" width="65" height="4"/>
					</g>
					<g clipPath="url(#ubsclip310)">
						<rect x="18.7" y="305.6" fill="#1ABCFF" width="65" height="22"/>
						<g transform="translate(-1571.039 1813)">
							<rect x="1589.7" y="-1516.4" fill="#FFFFFF" width="65" height="9"/>
							<rect x="1590.2" y="-1515.9" fill="none" stroke="#1ABCFF" width="64" height="8"/>
						</g>
						<rect x="18.7" y="288.6" fill="#006F9B" width="65" height="8"/>
						<rect x="18.7" y="285.6" fill="#86B22D" width="65" height="4"/>
						<rect x="18.7" y="277.6" fill="#008A98" width="65" height="8"/>
					</g>

				</svg>

			</div>
		</div>

	</section>
)}

export default Graph3_1