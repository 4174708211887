// extracted by mini-css-extract-plugin
export const graph = "graphs0_0-module--graph--2ad7T";
export const selectbox = "graphs0_0-module--selectbox--1Ixy3";
export const select = "graphs0_0-module--select--18E7o";
export const txt = "graphs0_0-module--txt--272ua";
export const box = "graphs0_0-module--box--2TiPD";
export const scene = "graphs0_0-module--scene--15-Ox";
export const legend = "graphs0_0-module--legend--2CYBP";
export const greed = "graphs0_0-module--greed--2vcjP";
export const yaxe = "graphs0_0-module--yaxe--3wbIO";
export const yaxevalue = "graphs0_0-module--yaxevalue--SDriV";
export const bars = "graphs0_0-module--bars--16I4n";
export const barbox = "graphs0_0-module--barbox--3OUD9";
export const nm = "graphs0_0-module--nm--3lnLo";
export const baranimated = "graphs0_0-module--baranimated--2hOF5";
export const bar = "graphs0_0-module--bar--3rfe-";
export const barline = "graphs0_0-module--barline--3ROGQ";
export const tip = "graphs0_0-module--tip--2GaIo";
export const bar_active = "graphs0_0-module--bar_active--1oifL";
export const arrow = "graphs0_0-module--arrow--2sAzZ";