import React from 'react'


import * as s from "./header.module.css"

const Header = (props) => {

  const data = props.data;

	return (
	<section className={(props.type===`mega`) ? s.megaheader : s.header}>
    <div className={s.upflex}>
      <div className={s.partner}>
        <div className={s.content} dangerouslySetInnerHTML={{__html: data.h0[0] }} />
        <div className={s.by} dangerouslySetInnerHTML={{__html: data.h0[1] }} />
      </div>
    </div>

    <div className={s.attention} dangerouslySetInnerHTML={{__html: data.attention[0] }} />
    <div className={s.attention} dangerouslySetInnerHTML={{__html: data.attention[1] }} />
	</section>
)}

export default Header