// extracted by mini-css-extract-plugin
export const graph = "graphs1_0-module--graph--371GT";
export const legendbox = "graphs1_0-module--legendbox--19f_l";
export const legend2 = "graphs1_0-module--legend2--2LeWb";
export const legendtxt = "graphs1_0-module--legendtxt--3q8We";
export const legendline0 = "graphs1_0-module--legendline0--x_Oyp";
export const legendline1 = "graphs1_0-module--legendline1--2PQTj";
export const legendline2 = "graphs1_0-module--legendline2--3KBMy";
export const legendline3 = "graphs1_0-module--legendline3--3mUd0";
export const legendline4 = "graphs1_0-module--legendline4--2x6jl";
export const legendline5 = "graphs1_0-module--legendline5--Bn1-a";
export const box = "graphs1_0-module--box--3AX-7";
export const scene = "graphs1_0-module--scene--3uAGq";
export const legend = "graphs1_0-module--legend--29R0j";
export const greedy = "graphs1_0-module--greedy--2TjNU";
export const yaxe = "graphs1_0-module--yaxe--3BfYg";
export const yaxevalue = "graphs1_0-module--yaxevalue--ubMnr";
export const greedx = "graphs1_0-module--greedx--PbVzg";
export const xaxe = "graphs1_0-module--xaxe--qS8xu";
export const xaxevalue = "graphs1_0-module--xaxevalue--1nlrn";
export const svg = "graphs1_0-module--svg--1xWP4";
export const ubsclip10 = "graphs1_0-module--ubsclip10--jyv2I";