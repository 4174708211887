import React from 'react'

import * as s from "./mega.module.css"

import { COLORS } from '../assets/icons.js'

import Graph from "./graph"
import Focus from "./focus"

import Ill0 from '../assets/ill2_0.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";


const Mega2 = (props) => {

	const data = props.data;

	return (
	<section className={s.mega2}>
	<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
		</ScrollAnimation>
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
		<p className={s.p} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
		</ScrollAnimation>

		<div className={s.lineblock}>
			<div className={s.lineblockline} style={{backgroundColor:COLORS[1]}}/>
			<Graph color={COLORS[2]} data={data.graph1}  nm={"2_0"}/>

			<div className={s.textblock}>
				<div className={s.textblockicon}>
				</div>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[2] }} />
				</ScrollAnimation>

			</div>

			<Graph color={COLORS[2]} data={data.graph2}  nm={"2_1"}/>

			<div className={s.graphtext}>
				<Graph color={COLORS[2]} data={data.graph3} nm={"2_2"}/>
				<div className={s.gtb}>
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<p className={s.pt} dangerouslySetInnerHTML={{__html: data.txt[3] }} />
					</ScrollAnimation>
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<p className={s.pt} dangerouslySetInnerHTML={{__html: data.txt[4] }} />
					</ScrollAnimation>
				</div>
			</div>

			<div className={s.textblock}>
				<div className={s.textblockicon} >
					<Ill0 />
				</div>
				<div>
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[5] }} />
					</ScrollAnimation>
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[6] }} />
					</ScrollAnimation>
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[7] }} />
					</ScrollAnimation>
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<p className={s.textblocktext} dangerouslySetInnerHTML={{__html: data.txt[8] }} />
					</ScrollAnimation>
				</div>
			</div>

			<Focus cid={2} data={data.focus} />

		</div>


	</section>
)}

export default Mega2