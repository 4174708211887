import React, {useEffect} from 'react'

import * as s from "./graphs4_1.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

const Graph4_1 = (props) => {

	const data = props.data;
	const xaxis = [0, 2, 4, 6, 8, 10, 12, 14]

	useEffect(() => {
		gsap.set(`.${s.tablebaranimated}`, {width: `0%`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.tablebaranimated}`, {width: `100%`, duration: 1, ease: `power2.out`, stagger: 0.02});
	}, [props.fired]);

	return (
	<section className={s.graph}>

		<div className={s.table}>

			<div className={s.greedx}>
				{
					xaxis.map((d,i) => (
			            <div
			              key={`x`+i}
			              className={s.xaxe}
			            >
			            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d }} />
			            </div>
		            ))
				}
			</div>

			<div className={s.tablebox}>
				{
		          data.data.map((d,i) => (
		            <div
		              key={`k`+i}
		              className={s.tableline}
		            >
						<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: d.name }} />

						<div className={s.tabletd1} >
							<div className={s.tablebaranimated}>
								<div className={s.tablebar0} style={{width: `${d.value/12*100}%`}} />
								<div className={s.tablebarvalue} dangerouslySetInnerHTML={{__html: d.value }} />
							</div>
						</div>
		            </div>
		          ))
		        }
			</div>

			<div className={s.legend2} dangerouslySetInnerHTML={{__html: data.legend[1] }} />

			<div className={s.legend} >
				<div className={s.legendline}> 
					<div className={s.legendqube} data-id={0} />
					<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
				</div>
			</div>

			<div className={s.ramka} />

		</div>

	</section>
)}

export default Graph4_1