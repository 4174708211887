import React, {useEffect} from 'react'

import * as s from "./graphs5_1.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

const Graph5_1 = (props) => {

	const data = props.data;
	const xaxis = [-300, -200, -100, 0, 100, 200, 300, 400]

	useEffect(() => {
		gsap.set(`.${s.tablebaranimated}`, {width: `0%`});
		gsap.set(`.${s.tablebaranimated2}`, {width: `0%`});
	}, []);

	useEffect(() => {
		if(props.fired){
			gsap.to(`.${s.tablebaranimated}`, {width: `57%`, duration: 1, ease: `power1.out`, stagger: 0.025});
			gsap.to(`.${s.tablebaranimated2}`, {width: `43%`, duration: 1, ease: `power1.out`, stagger: 0.025});
		}
	}, [props.fired]);

	return (
	<section className={s.graph} data-id={`1_1`}>


		<div className={s.legend} >
			<div className={s.legendline}> 
				<div className={s.legendqube} data-id={0} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
			</div>
			<div className={s.legendline}>
				<div className={s.legendqube} data-id={1} />
				<div className={s.legendtxt} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
			</div>
		</div>


		<div className={s.table}>

			<div className={s.greedx}>
				{
					xaxis.map((d,i) => (
			            <div
			              key={`x`+i}
			              className={s.xaxe}
			              data-id={i}
			            >
			            	<div className={s.xaxevalue} dangerouslySetInnerHTML={{__html: d  }} />
			            </div>
		            ))
				}
			</div>

			<div className={s.tablebox}>
				{
		          data.data.map((d,i) => (
		            <div
		              key={`k`+i}
		              className={s.tableline}
		            >
						
						<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: d.year }} />

						<div className={s.tabletd1} >
							<div className={s.line} />
							<div className={s.tablebaranimated}>
								<div className={s.tablebar0} style={{width: `${Math.abs(d.values[1])/400*100}%`}} >
									<div className={s.val} dangerouslySetInnerHTML={{__html: d.values[1] }} />
								</div>
							</div>
							<div className={s.tablebaranimated2} >
								<div className={s.tablebar1} style={{width: `${Math.abs(d.values[0])/300*100}%`}} >
									<div className={s.val2} dangerouslySetInnerHTML={{__html: Math.abs(d.values[0]) }} />
								</div>
							</div>
						</div>
		            </div>
		          ))
		        }
			</div>



		</div>

	</section>
)}

export default Graph5_1