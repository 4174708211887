// extracted by mini-css-extract-plugin
export const graph = "graphs2_2-module--graph--2m2Hl";
export const selectbox = "graphs2_2-module--selectbox--1nRvL";
export const select = "graphs2_2-module--select--3lU7y";
export const txt = "graphs2_2-module--txt--3u2df";
export const box = "graphs2_2-module--box--2chE4";
export const scene = "graphs2_2-module--scene--1l7eF";
export const legend = "graphs2_2-module--legend--37hbe";
export const greed = "graphs2_2-module--greed--2_TER";
export const yaxe = "graphs2_2-module--yaxe--N__eM";
export const yaxevalue = "graphs2_2-module--yaxevalue--vDkF5";
export const bars = "graphs2_2-module--bars--3Pt6E";
export const barbox = "graphs2_2-module--barbox--23Ir1";
export const nm = "graphs2_2-module--nm--2tqw1";
export const baranimated = "graphs2_2-module--baranimated--2QzrB";
export const bar = "graphs2_2-module--bar--3bJPm";
export const barline = "graphs2_2-module--barline--14zc-";
export const tip = "graphs2_2-module--tip--ZB3sO";
export const bar_active = "graphs2_2-module--bar_active--3rPIS";
export const arrow = "graphs2_2-module--arrow--2IFLK";