import React, {useEffect} from 'react'

import * as s from "./graphs0_1.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

const Graph0_1 = (props) => {

	const data = props.data;
	const part1 = data.data.slice(0, data.data.length / 2);
	const part2 = data.data.slice(data.data.length / 2);

	useEffect(() => {
		gsap.set(`.${s.tablebaranimated}`, {width: `0%`});
	}, []);

	useEffect(() => {
		if(props.fired)
			gsap.to(`.${s.tablebaranimated}`, {width: `100%`, duration: 1, ease: `power1.out`, stagger: 0.025});
	}, [props.fired]);

	return (
	<section className={s.graph} data-id={`0_1`}>

		<div className={s.table} data-id={"0"}>

			<div className={s.tablehead}>
				<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
				<div className={s.tabletd1} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
				<div className={s.tabletd2} dangerouslySetInnerHTML={{__html: data.legend[2] }} />
				<div className={s.tabletd3} />
			</div>

			<div className={s.tablebox}>
			{
	          part1.map((d,i) => (
	            <div
	              key={`k`+i}
	              className={s.tableline}
	            >
					<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: d.name }} />
					<div className={s.tabletd1} dangerouslySetInnerHTML={{__html: d.rank }} />
					<div className={s.tabletd2} >
						<div className={s.tablebaranimated}>
							<div className={s.tablebar} style={{width: `${d.yoy/3.1}%`}} />
						</div>
					</div>
					<div className={s.tabletd3} dangerouslySetInnerHTML={{__html: d.yoy + `%` }} />
	            </div>
	          ))
	        }
			</div>
		</div>

		<div className={s.table} data-id={"1"}>

			<div className={s.tablehead}>
				<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: data.legend[0] }} />
				<div className={s.tabletd1} dangerouslySetInnerHTML={{__html: data.legend[1] }} />
				<div className={s.tabletd2} dangerouslySetInnerHTML={{__html: data.legend[2] }} />
				<div className={s.tabletd3}/>
			</div>

			<div className={s.tablebox}>
			{
	          part2.map((d,i) => (
	            <div
	              key={`k`+i}
	              className={s.tableline}
	            >
					<div className={s.tabletd0} dangerouslySetInnerHTML={{__html: d.name }} />
					<div className={s.tabletd1} dangerouslySetInnerHTML={{__html: d.rank }} />
					<div className={s.tabletd2} >
						<div className={s.tablebaranimated}>
							<div className={s.tablebar} style={{width: `${d.yoy/4}%`}} />
						</div>
					</div>
					<div className={s.tabletd3} dangerouslySetInnerHTML={{__html: d.yoy + `%` }} />
	            </div>
	          ))
	        }
			</div>
		</div>

	</section>
)}

export default Graph0_1