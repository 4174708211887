import React from 'react'
import * as s from "./more.module.css"
import { COLORS, ICONSMORE } from '../assets/icons.js'
// import { navigate } from "gatsby"
import { track } from '../utils/index.js'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import _filter from 'lodash/filter';

const More = (props) => {

  let completed = _filter(props.cookies, function(d) { return d; }).length;
  let isequal = true; //(completed === props.cookies.length)

	return (
  <section className={s.more}>
		<div className={s.h1} dangerouslySetInnerHTML={{__html: props.explore }} />
		<div className={s.line} />
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
    <div className={s.flex}>
        
        {
          props.cookies.map((d,i) => (
           <div
              key={`k`+i}
              className={s.btncontainer}
              data-id={i}
              style={{display: (i===props.cid)?"none":"block"}}
            >
              <div
                key={`k`+i}
                className={s.btn}
                data-id={i}
                style={{display: (i===props.cid)?"none":"block"}}
                role = "button"
                tabIndex="0"
                onKeyDown={()=>{
                }}
                onClick={()=>{
                  props.setPart(i+1);
                }}
               >

                  <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158">
                    <g transform="translate(-16 -28)">
                      <path d="M95.33 28.5a78.83 78.83 0 11-78.83 78.83A78.83 78.83 0 0195.33 28.5z" fill="#f6f6f6" opacity=".1"/>
                      <text transform="translate(95.5 125.454)" fill={COLORS[i]} fontSize="21" fontFamily="Metric-Medium, Metric" fontWeight="500" textAnchor="middle">
                        <tspan x="0" y="0">{props.data.megatrends[i].h1}</tspan>
                      </text>
                      <g opacity={(props.cookies[i])?1:0}>
                        <path d="M104.741 134.011H85.469a2.978 2.978 0 00-2.969 2.97v18.11a2.978 2.978 0 002.969 2.97h19.272a2.978 2.978 0 002.969-2.97v-18.11a2.978 2.978 0 00-2.969-2.97zm-2.093 8.7l-9.773 9.774-4.8-4.8a1.437 1.437 0 010-2.033 1.437 1.437 0 012.033 0l2.763 2.763 7.74-7.74a1.438 1.438 0 012.034 0 1.438 1.438 0 01.003 2.033z" fill={COLORS[i]}/>
                      </g>
                      <g className={s.border} fill="none">
                          <path d="M95.33 28.5a78.83 78.83 0 11-78.83 78.83A78.83 78.83 0 0195.33 28.5z"/>
                          <path d="M95.33 30.5c-10.373 0-20.435 2.031-29.905 6.037a76.83 76.83 0 00-13.05 7.083 77.392 77.392 0 00-11.372 9.383 77.392 77.392 0 00-9.383 11.372 76.83 76.83 0 00-7.083 13.05C20.53 86.895 18.5 96.957 18.5 107.33c0 10.373 2.031 20.434 6.037 29.905a76.83 76.83 0 007.083 13.05 77.392 77.392 0 009.383 11.372 77.393 77.393 0 0011.372 9.382 76.831 76.831 0 0013.05 7.084c9.47 4.005 19.532 6.036 29.905 6.036 10.373 0 20.434-2.03 29.905-6.036a76.832 76.832 0 0013.05-7.084 77.393 77.393 0 0011.372-9.382 77.393 77.393 0 009.382-11.372 76.832 76.832 0 007.084-13.05c4.005-9.471 6.036-19.532 6.036-29.905 0-10.373-2.03-20.435-6.036-29.905a76.831 76.831 0 00-7.084-13.05 77.393 77.393 0 00-9.382-11.372 77.392 77.392 0 00-11.372-9.383 76.83 76.83 0 00-13.05-7.083C115.764 32.53 105.703 30.5 95.33 30.5m0-2c43.536 0 78.83 35.293 78.83 78.83 0 43.536-35.294 78.83-78.83 78.83-43.537 0-78.83-35.294-78.83-78.83 0-43.537 35.293-78.83 78.83-78.83z" fill={COLORS[i]}/>
                      </g>
                    </g>

                    <g transform="translate(10 4)">
                     {
                        ICONSMORE[i]
                     }
                     </g>
                  </svg>

               </div>
            </div>
          ))
        }



           <div
              key={`k`+7}
              className={s.btncontainer}
              data-id={7}
            >
              <div
                className={s.btn}
                data-id={7}
                role = "button"
                tabIndex="0"
                onKeyDown={()=>{
                }}
                onClick={()=>{
                  props.setPart(8);
                }}
                style={{opacity: (isequal)?1:0.5, cursor: (isequal)?"pointer":"auto"}}
               >

                  <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158">
                    <g transform="translate(-16 -28)">
                      <path d="M95.33 28.5a78.83 78.83 0 11-78.83 78.83A78.83 78.83 0 0195.33 28.5z" fill="#f6f6f6" opacity=".1"/>
                      <text transform="translate(95.5 125.454)" fill="#242830" fontSize="21" fontFamily="Metric-Medium, Metric" fontWeight="500" textAnchor="middle">
                        <tspan x="0" y="0">{`SECTOR IMPACT`}</tspan>
                      </text>
  
                      <g className={s.border} fill="none">
                          <path d="M95.33 28.5a78.83 78.83 0 11-78.83 78.83A78.83 78.83 0 0195.33 28.5z"/>
                          <path d="M95.33 30.5c-10.373 0-20.435 2.031-29.905 6.037a76.83 76.83 0 00-13.05 7.083 77.392 77.392 0 00-11.372 9.383 77.392 77.392 0 00-9.383 11.372 76.83 76.83 0 00-7.083 13.05C20.53 86.895 18.5 96.957 18.5 107.33c0 10.373 2.031 20.434 6.037 29.905a76.83 76.83 0 007.083 13.05 77.392 77.392 0 009.383 11.372 77.393 77.393 0 0011.372 9.382 76.831 76.831 0 0013.05 7.084c9.47 4.005 19.532 6.036 29.905 6.036 10.373 0 20.434-2.03 29.905-6.036a76.832 76.832 0 0013.05-7.084 77.393 77.393 0 0011.372-9.382 77.393 77.393 0 009.382-11.372 76.832 76.832 0 007.084-13.05c4.005-9.471 6.036-19.532 6.036-29.905 0-10.373-2.03-20.435-6.036-29.905a76.831 76.831 0 00-7.084-13.05 77.393 77.393 0 00-9.382-11.372 77.392 77.392 0 00-11.372-9.383 76.83 76.83 0 00-13.05-7.083C115.764 32.53 105.703 30.5 95.33 30.5m0-2c43.536 0 78.83 35.293 78.83 78.83 0 43.536-35.294 78.83-78.83 78.83-43.537 0-78.83-35.294-78.83-78.83 0-43.537 35.293-78.83 78.83-78.83z" fill="#242830"/>
                      </g>
                    </g>


                    <g  transform="translate(-845 -6095)" opacity={(isequal)?0:1}>
                      <g  transform="translate(626.85 6425.497)">
                        <g  transform="translate(293.127 -224.497)">
                          <path  d="M310.2-215.919h-1.4c0-.294,0-2.477-.012-2.65a4.875,4.875,0,0,0-4.855-4.525,4.867,4.867,0,0,0-4.86,4.59c0,.152-.007,2.38-.007,2.585h-1.4c0-.025,0-2.525.01-2.663a6.27,6.27,0,0,1,6.26-5.914,6.284,6.284,0,0,1,6.255,5.844C310.193-218.486,310.2-215.945,310.2-215.919Z" transform="translate(-297.657 224.497)" fill="#242830"/>
                        </g>
                        <path  d="M307.229-209.8H291.564a2.421,2.421,0,0,0-2.414,2.414v14.721a2.421,2.421,0,0,0,2.414,2.414h15.665a2.421,2.421,0,0,0,2.414-2.414v-14.721A2.421,2.421,0,0,0,307.229-209.8Zm-5.075,14.741a.884.884,0,0,1-.882.882h-3.751a.884.884,0,0,1-.882-.882l.581-4.82a3.159,3.159,0,0,1-.995-2.3,3.171,3.171,0,0,1,3.171-3.171,3.171,3.171,0,0,1,3.171,3.171,3.159,3.159,0,0,1-.995,2.3Z" transform="translate(0 -7.827)" fill="#242830"/>
                      </g>
                    </g>


                    <g transform="translate(60 28)">
                      <path d="M14.822,10.14A5.071,5.071,0,1,1,19.892,5.07,5.077,5.077,0,0,1,14.822,10.14Zm0-8.451A3.38,3.38,0,1,0,18.2,5.07,3.384,3.384,0,0,0,14.822,1.689Z" transform="translate(2.929 0.001)" fill="#242830"/>
                      <path d="M5.626,35.734a3.384,3.384,0,0,1-3.11-4.7l9.3-21.972A3.374,3.374,0,0,1,14.926,7H29.568a5.647,5.647,0,0,1,5.64,5.64,4.5,4.5,0,0,1-8.532,2.011l-.443-.886-1.17-.005h-7.9L14.41,20.27l2.557.928a8.5,8.5,0,0,1,5.562,7.94v3.213a3.38,3.38,0,1,1-6.761,0V29.14a1.7,1.7,0,0,0-1.11-1.585l-2.885-1.05-3.03,7.163A3.374,3.374,0,0,1,5.626,35.734Zm9.3-27.045a1.687,1.687,0,0,0-1.557,1.033l-9.3,21.972a1.691,1.691,0,1,0,3.115,1.317l3.343-7.9a.847.847,0,0,1,1.068-.465L15.24,25.97a3.392,3.392,0,0,1,2.223,3.171v3.211a1.69,1.69,0,1,0,3.38,0V29.14a6.8,6.8,0,0,0-4.452-6.353L13,21.555a.846.846,0,0,1-.49-1.124l3.32-7.846a.843.843,0,0,1,.777-.516h7.616V8.689ZM28.19,13.9a2.81,2.81,0,0,0,5.329-1.254,3.955,3.955,0,0,0-3.95-3.952H25.914v3.384l.774,0a.586.586,0,0,1,.071-.007h3.38a.845.845,0,0,1,0,1.69H28.123Z" transform="translate(-2.249 4.832)" fill="#242830"/>
                    </g>

                  </svg>

               </div>
            </div>






		</div>
    </ScrollAnimation>
	</section>
)}

export default More