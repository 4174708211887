// extracted by mini-css-extract-plugin
export const graph = "graphs1_2-module--graph--oZz5H";
export const selectbox = "graphs1_2-module--selectbox--3hSNZ";
export const select = "graphs1_2-module--select--153_w";
export const txt = "graphs1_2-module--txt--31y96";
export const legendx = "graphs1_2-module--legendx--1dCoO";
export const legendy = "graphs1_2-module--legendy--3bAfw";
export const box = "graphs1_2-module--box---MtCz";
export const scene = "graphs1_2-module--scene--1YfGW";
export const greedy = "graphs1_2-module--greedy--1HvgT";
export const yaxe = "graphs1_2-module--yaxe--3Dddh";
export const yaxevalue = "graphs1_2-module--yaxevalue--1Pt8v";
export const greedx = "graphs1_2-module--greedx--1kkK5";
export const xaxe = "graphs1_2-module--xaxe--3bEAF";
export const xaxevalue = "graphs1_2-module--xaxevalue--30F-8";
export const fieldbox = "graphs1_2-module--fieldbox--_wxCz";
export const fieldboxscroll = "graphs1_2-module--fieldboxscroll--_1Fo-";
export const field = "graphs1_2-module--field--2Egmj";
export const dot = "graphs1_2-module--dot--O5CQZ";
export const dotnm = "graphs1_2-module--dotnm--n9Vt9";
export const dotsq = "graphs1_2-module--dotsq--169Wb";
export const dot_active = "graphs1_2-module--dot_active--Kz5Mx";
export const swipe = "graphs1_2-module--swipe--1Iifq";
export const swipetext = "graphs1_2-module--swipetext--30GSt";