// extracted by mini-css-extract-plugin
export const graph = "graphs5_2-module--graph--1GslH";
export const selectbox = "graphs5_2-module--selectbox--C3ARg";
export const select = "graphs5_2-module--select--2--Zx";
export const txt = "graphs5_2-module--txt--21jt1";
export const box = "graphs5_2-module--box--3ODT4";
export const scene = "graphs5_2-module--scene--xgEaG";
export const legend = "graphs5_2-module--legend--366dH";
export const greed = "graphs5_2-module--greed--2z4mh";
export const yaxe = "graphs5_2-module--yaxe--3eeLX";
export const yaxevalue = "graphs5_2-module--yaxevalue--1fRPP";
export const lineV = "graphs5_2-module--lineV--k432o";
export const bars = "graphs5_2-module--bars--iW8FC";
export const barbox = "graphs5_2-module--barbox--2oGde";
export const labels_w = "graphs5_2-module--labels_w--3dFBT";
export const nm = "graphs5_2-module--nm--2u0FZ";
export const baranimated = "graphs5_2-module--baranimated--3PPya";
export const bar = "graphs5_2-module--bar--t9a7E";
export const barline1 = "graphs5_2-module--barline1--34iTT";
export const tip = "graphs5_2-module--tip--2JMoM";
export const bar_active = "graphs5_2-module--bar_active--2Sxs0";
export const point = "graphs5_2-module--point--3B9ww";
export const barline2 = "graphs5_2-module--barline2--2vFXv";
export const arrow = "graphs5_2-module--arrow--3blbo";
export const labels = "graphs5_2-module--labels--pf7pt";
export const barline = "graphs5_2-module--barline--3KTqZ";