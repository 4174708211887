// V2

import React, { useEffect, useRef } from 'react'

import * as s from "./graphs5_0.module.css"

import { gsap } from "gsap/dist/gsap";
// import { EasePack } from 'gsap/all'

// import G50line from '../../assets/g5_0_line.svg'

const Graph5_0 = (props) => {
	// const svgRef = useRef(null);
	const ref = useRef(null);
	// const lineRef = useRef(null);
	const rectList = useRef(null);
	// const pointList = useRef(null);
	// const lineTextRef = useRef(null);
	const linesW = useRef(null);
	const heightList1 = useRef([]);
	const heightList2 = useRef([]);
	const data = props.data;
	// const [val, setVal] = useState(0);
	const yaxis = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
	const yaxis2 = [32, 29, 26, 23, 20, 17, 14, 11, 8, 5, 2]

	// --------------
	function drawLine(points, lines, index) {

    var p1 = {x: points[index].offsetLeft, y: points[index].offsetTop};
    if(!points[index + 1]) return;
    var p2 = {x: points[index + 1].offsetLeft, y: points[index + 1].offsetTop};

    var a = p1.x - p2.x;
    var b = p1.y - p2.y;
    var length = Math.sqrt( a*a + b*b );

    var angleDeg = Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI;

    var pointWidth = points[0].clientWidth / 2;
    var pointHeight = points[0].clientWidth / 2;

    lines[index].style.width = length + 'px';
    lines[index].style.left = (p1.x + pointWidth)+ 'px';
    lines[index].style.top = (p1.y + pointHeight) + 'px';

    lines[index].style.transform = "rotate(" + angleDeg + "deg)";

}
function calcLine() {
		if(!ref.current) return;
		let points = ref.current.querySelectorAll(`.${s.point}`)
    let div = document.createElement('div');
    points.forEach( p => {
				let line = document.createElement('div');
				// line.style.opacity = 0;
        line.classList = `${s.pointLine} sip_poinline`;
        div.appendChild(line);
		})
		linesW.current.innerHTML = '';
		linesW.current.appendChild(div);
		const lines = div.querySelectorAll(`.${s.pointLine}`);
		
    lines.forEach( (line, i) => {
        drawLine(points, lines, i)
    })
}
	// --------------

	useEffect(() => {
		ref.current.querySelectorAll(`.${s.barline1}`).forEach( el => {
			heightList1.current.push(el.style.height)
		})
		ref.current.querySelectorAll(`.${s.barline2}`).forEach( el => {
			heightList2.current.push(el.style.height)
		})

		rectList.current = ref.current.querySelectorAll(`.${s.point}`);
		setTimeout(() => {
			window.addEventListener("resize", calcLine, false); 
			calcLine();
			gsap.set(linesW.current.querySelectorAll(`.${s.pointLine}`), {opacity: 0});
		}, 500);

		gsap.set(`.${s.barline1}`, {height: `0%`, transformOrigin: `center center`});
		gsap.set(`.${s.barline2}`, {height: `0%`, transformOrigin: `center center`});
		gsap.set(ref.current.querySelectorAll(`.${s.point}`), {opacity: 0});

		return () => {
			window.removeEventListener('resize', calcLine);
		}
	}, []);

	useEffect(() => {
		if(props.fired) {

			gsap.to(`.${s.barline1}`, {height: (i) => heightList1.current[i], duration: 0.5, ease: `power4.out`, stagger: 0.025});
			gsap.to(`.${s.barline2}`, {height: (i) => heightList2.current[i], duration: 0.8, ease: `power4.out`, stagger: 0.05});
			gsap.to(ref.current.querySelectorAll(`.${s.point}`), {opacity: 1, duration: 0.8, delay: 0.5, ease: `power4.out`, stagger: 0.05});

			setTimeout(() => {
				gsap.to(linesW.current.querySelectorAll(`.${s.pointLine}`), {opacity: 1, duration: 1, ease: `power4.out`, stagger: 0.05});
			}, 1200);
			
		}
	}, [props.fired]);

	return (
		<section className={s.graph} ref={ref}>

		<div className={s.box}>
				<div className={s.scene}>
					<div className={s.greed}>
						{
							yaxis.map((d,i) => (
								<div
									key={`k`+i}
									className={s.yaxe}
								>
									<div className={s.yaxevalue} dangerouslySetInnerHTML={{__html: d }} />
									<div className={s.yaxevalue2} dangerouslySetInnerHTML={{__html: yaxis2[i] }} />
								</div>
							))
						}
					</div>
					<div className={s.percents0}>{`%`}</div>
					<div className={s.percents1}>{`%`}</div>
					<div className={s.legend}>
						<div dangerouslySetInnerHTML={{__html: data.legend[0] }} />
						<div dangerouslySetInnerHTML={{__html: data.legend[1] }} />
						<div dangerouslySetInnerHTML={{__html: data.legend[2] }} />
					</div>
					<div className={s.bars} >
					{
								data.data.map((d,i) => (
									<div
										key={`k`+i}
										className={s.barbox}
									>

							<div className={s.baranimated}>
								<div className={s.barline1} style={{height: `${(100/10) * Math.abs(d.values[1])}%`}} />
								<div style={{height: '100%'}}>
								<div>
									<div className={s.nm} dangerouslySetInnerHTML={{__html: d.years }} />
								</div>


								</div>
								<div className={s.barline2} style={{height: `${(100/10) * Math.abs(d.values[2])}%`}} />
							</div>
									</div>
								))
								
								
							}

							</div>
					

							<div className={s.point_w}>
							{
								data.data.map((d,i) => (
									<div
										key={`k`+i}
										className={`sip_point ${s.point}`} style={{height: `${(100/10) * Math.abs(d.values[0])}%`}}
									>
										<div className={`sip_point_name ${s.pointName}`}>{d.name}</div>
									</div>
								)
							)}
							</div>

					<div ref={linesW} />

				</div>

			</div>

		</section>
	)
	


}



export default Graph5_0