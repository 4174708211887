import React from 'react'

import * as s from "./focus.module.css"

import { COLORS, withpath } from '../assets/icons.js'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
const Focus = (props) => {

	const data = props.data;
	const id = props.cid;
	const color = COLORS[id];

	return (
	<section className={s.focus}>
		<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
			<div className={s.h1} style={{color: color}} dangerouslySetInnerHTML={{__html: data.h1 }} />
		</ScrollAnimation>
		{
          data.sectors.map((d,i) => (
            <ScrollAnimation key={`k`+i} animateIn="fadeIn" animateOnce={true}>
            <div
              className={s.sector}
             >
             	<div className={s.icon}>
					<img 
						src={withpath(`/img/focus/f${id + "_" + i}.svg`)}
						alt="icon"
					/>
             	</div>
             	<div className={s.txt} dangerouslySetInnerHTML={{__html: d }} />
             </div>
             </ScrollAnimation>
           ))
        }
	</section>
)}

export default Focus