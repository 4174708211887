import React, { useState } from 'react'
import Header from './header'
import Select from 'react-select'
import { withpath } from '../assets/icons.js'
import * as s from "./impact.module.css"
import ImageFadeIn from "react-image-fade-in";
import { track } from '../utils/index.js'
import Globeicon from '../assets/globeicon.svg';

const Impact = (props) => {
	const data = props.data;
	const [impact, setImpact] = useState(0);
	let imp = data.impact.data;
	const t = [`sn_1`, `sn0`, `sn1`];
	const clr = [`#CC0000`,`#F08D48`, `#50B063`];
	let arr = [
		t[imp[impact].values[0] + 1],
		t[imp[impact].values[1] + 1],
		t[imp[impact].values[2] + 1],
		t[imp[impact].values[3] + 1]
	]
	let carr = [
		clr[imp[impact].values[0] + 1],
		clr[imp[impact].values[1] + 1],
		clr[imp[impact].values[2] + 1],
		clr[imp[impact].values[3] + 1]
	]

	let options = data.impact.data.map((d,i) => ( { value: i, label: d.name } ))
	options.sort(function (a, b) {
	  if (a.label > b.label) {
	    return 1;
	  }
	  if (a.label < b.label) {
	    return -1;
	  }
	  return 0;
	});



	const customStyles = {
		control: (styles, state) => ({ 
			...styles, 
			backgroundColor: `#262A33`,
			color: `white`,
			borderRadius: 0,
			border: 0,
    		boxShadow: 'none',
    		height: 46,
			padding: 0,
			cursor: 'pointer'
		}),
		option: (styles, { isSelected }) => ({
			...styles, 
			backgroundColor: isSelected ? `#4B9399` : `#262A33`,
			':hover': { backgroundColor: `#4B9399` },
			color: `white`,
			borderRadius: 0,
			cursor: 'pointer'
		}),
		placeholder: styles => ({ ...styles, color: `white` }),
		valueContainer: styles => ({ ...styles, overflow: "visible" }),
		singleValue: styles => ({ ...styles, color: `white` }),
		input: styles => ({ ...styles, color: `white` }),
		menu: styles => ({ ...styles, backgroundColor: `#262A33`, marginTop: 0, marginBottom: 0, cursor: 'pointer' }),
		menuList: (provided, state) => ({
		   ...provided,
		   paddingTop: 0,
		   paddingBottom: 0,
		}),
	}



	return (
		<section className={s.section}>
			
			<div className={s.bgs}>
				<div className={s.bg}>
					<ImageFadeIn 
						width={856} 
						height={539}
						className={s.bgimg}
						src={withpath(`/img/bg.png`)}
						srcSet={withpath(`/img/bg@2x.png`) + ` 2x`}
						alt="icon"
					/>
				</div>
			</div>

			<div className={s.flex}>

				<div className={s.stikybox}>
					<div
						className={s.stikyboxblock} 
						onKeyDown={()=>{}}
						role="button"
						aria-label="toglobe"
						tabIndex={0}
						onClick={()=>{
							props.setPart(0);
						}}
					>
						<div className={s.back}>
						  <div className={s.returntxt} dangerouslySetInnerHTML={{__html: data.return }} />
						  <Globeicon />
						</div>
					</div>
				</div>

				<Header data={data} />

				<div className={s.impact}>
					<div className={s.left}>
						<div className={s.h1} dangerouslySetInnerHTML={{__html: data.impact.h1 }} />
						<div className={s.h2} dangerouslySetInnerHTML={{__html: data.impact.txt }} />

						<div className={s.selectbox}>
						<Select
							instanceId="ubs-select2"
							placeholder={options.find(d => d.value === impact).label}
							options={options}
							styles={customStyles}
							isSearchable={false}
							className={s.select}
							components={{IndicatorSeparator: () => null}}
							onKeyDown={(e)=>{}}
							onChange={(e)=>{
								track(3, options.find(d => d.value === e.value).label)
								setImpact(e.value)
							}}
							value={options.find(d => d.value === impact).label}
						/>
						</div>

						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.impact.data[impact].txt }} />
					</div>
					<div className={s.right}>
						
						<ImageFadeIn
							key={impact}
							className={s.icon}
							src={withpath(`/img/impact/i${impact}.svg`)} 
							alt="icon"
						/>

					</div>
				</div>

				<div className={s.snapshot}>
					<div className={s.snaph2} dangerouslySetInnerHTML={{__html: data.impact.h2 }} />
					<div className={s.growbox}>
					{

			          data.impact.legend.map((d,i) => (
			            <div
			              key={`l`+i}
			              className={s.snapbox}
			             >
				            <div className={s.snap}>
				             	<div className={s.snaptxt} dangerouslySetInnerHTML={{__html: d }} />
				             	<div className={s.snapicon} >
									<svg 
										className={arr[i] + " " + s.svgrotate} 
										xmlns="http://www.w3.org/2000/svg" 
										width="48.355" 
										height="55.217" 
										viewBox="0 0 48.355 55.217"
									>
										<g transform="translate(43.405 3.5) rotate(90)">
											<path className={s.snapiconstroke} d="M0,0,19.227,19.228,0,38.455" transform="translate(27.54 0)" fill="none" stroke={carr[i]} strokeLinecap="round" strokeWidth="7"/>
											<line className={s.snapiconstroke} x2="46.249" transform="translate(0 19.228)" fill="none" stroke={carr[i]} strokeLinecap="round" strokeWidth="7"/>
										</g>
									</svg>
				             	</div>
				             </div>
			             </div>
			          ))
					}
					</div>
				</div>

			</div>

		</section>
)}

export default Impact